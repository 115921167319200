/**
 * Staffling
 */

import React from 'react';
import clsx from 'clsx';

import Price from 'components/Price';
import Text from 'components/Text';
import { Price as PriceType } from 'models/price';
import { getPriceProps } from 'utils/business-logic';
import { formatPriceText } from 'utils/price';

interface Props {
	className?: string;
	volumePrice: PriceType;
}

export default function Staffling({ className, volumePrice }: Props) {
	const price = { ...volumePrice };
	// TODO: can be removed when backend sends correct priceType, ticket coming
	// Backend may send Base so has to be set to Campaign
	// for correct pricetype to be displayed
	if (price.priceType === 'Base') {
		price.priceType = 'Campaign';
	}
	const { title, comparisonPrice } = price;

	return (
		<div
			className={clsx(
				'flex items-center justify-between rounded-border bg-informationLighter p-4',
				className,
			)}
		>
			<div>
				<Text as="p" className="font-bold">
					{title}
				</Text>
				{comparisonPrice && (
					<Text as="pSmall" className="mt-1">
						{comparisonPrice.title}{' '}
						{formatPriceText(
							comparisonPrice.price,
							comparisonPrice.priceSymbol,
						)}
					</Text>
				)}
			</div>
			<div className="pr-4">
				<Price {...getPriceProps(price, false)} size="micro" />
			</div>
		</div>
	);
}
Staffling.displayName = 'Staffling';
