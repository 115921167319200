import React from 'react';
import clsx from 'clsx';

import Img from 'components/Img';
import Text from 'components/Text';
import { Placement } from 'models/api/ProductCustomization';

export type SelectablePlacement = Placement & {
	isSelected: boolean;
};

interface Props {
	heading: string;
	onPlacementClick: (placement: Placement) => void;
	placementDisabledText: string;
	placements: SelectablePlacement[] | undefined;
}

export default function PlacementView({
	onPlacementClick,
	placements,
	heading,
	placementDisabledText,
}: Props) {
	return (
		<div>
			<Text as="h3" text={heading} />
			<ul className="mt-6 grid grid-cols-4 gap-2">
				{placements?.map((placement) => (
					<li key={placement.id} className="col-span-2">
						<button
							type="button"
							onClick={
								placement.isSelected
									? undefined
									: () => onPlacementClick(placement)
							}
							aria-disabled={placement.isSelected}
							className={clsx(
								'relative flex w-full flex-col items-center rounded-border border-1 border-greyDark p-4 aria-disabled:cursor-not-allowed',
								!placement.isSelected && 'hover:bg-greyLight',
							)}
						>
							<Img className="size-32" src={placement.asset?.location} />
							<Text as="p" className="mt-4 font-bold" text={placement.title} />
							{placement.isSelected && (
								<div className="absolute inset-0 bg-white bg-opacity-50 p-2">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="0.1"
										stroke="CurrentColor"
										aria-hidden="true"
										className="h-full w-full"
									>
										<path d="M 0 0 L24 24 M24 0 L0 24" />
									</svg>
									<Text
										as="span"
										styleAs="pSmall"
										text={placementDisabledText}
										className="absolute inset-x-4 top-1/2 -translate-y-1/2 bg-white"
									/>
								</div>
							)}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
}

PlacementView.displayName = 'ProductPrintPopover_PlacementView';
