/**
 * GiftCardButton
 */

import React, { ReactNode } from 'react';

import Icon from 'components/Icon';
import { getTestDataAttrFrom } from 'utils/helpers';

interface Props {
	children: ReactNode;
	text: string;
}

/** Button component with plus/arrow icon */
export default function GiftCardButton({ text, children }: Props) {
	return (
		<details className="group/gift-card-button">
			<summary
				data-cy={getTestDataAttrFrom('gift-card-button')}
				className="group/gift-card-summary inline-flex cursor-pointer list-none items-center align-middle [&::-webkit-details-marker]:hidden"
			>
				<Icon
					className="mr-2 group-open/gift-card-button:hidden"
					icon="plus"
					color="black"
					backgroundColor="greyLight"
				/>
				<Icon
					className="mr-2 hidden group-open/gift-card-button:inline-block"
					icon="arrow"
					color="white"
					direction="up"
					backgroundColor="julaRed"
				/>
				<span className="underline group-hover/gift-card-summary:no-underline">
					{text}
				</span>
			</summary>
			{children}
		</details>
	);
}
GiftCardButton.displayName = 'GiftCardButton';
