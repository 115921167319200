import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';

import IconButton from 'components/IconButton';
import Img from 'components/Img';
import { Review } from 'components/Reviews';
import { ImageId, ReviewImage } from 'hooks/product-details';
import cn from 'utils/cn';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	images: ReviewImage[];
	/** The slider needs to be visible to be rendered correctly */
	isVisible: boolean;
	selectedReviewImageId: ImageId | undefined;
	setSelectedReviewImageId: (id: ImageId) => void;
}

const ReviewImagesView = forwardRef<HTMLDivElement, Props>(
	(
		{
			className,
			images,
			isVisible,
			selectedReviewImageId,
			setSelectedReviewImageId,
		},
		ref,
	) => {
		const { t } = useI18n();
		const [isLoaded, setIsLoaded] = useState(false);
		const [currentIndex, setCurrentIndex] = useState(0);
		const [sliderRef, instanceRef] = useKeenSlider({
			loop: true,
			slides: { perView: 1, origin: 'center' },
			slideChanged(slider) {
				const currentIndex = slider.track.details.rel;
				const currentIndexId = images[currentIndex]?.id;
				setCurrentIndex(currentIndex);
				if (currentIndexId) {
					setSelectedReviewImageId(currentIndexId);
				}
			},
			created: () => {
				setIsLoaded(true);
			},
			destroyed() {
				setIsLoaded(false);
			},
		});

		useEffect(() => {
			if (isLoaded && selectedReviewImageId) {
				instanceRef.current?.moveToIdx(
					images.findIndex((image) => image.id === selectedReviewImageId),
					false,
					{
						duration: 0,
					},
				);
			}
		}, [isLoaded]);

		// it's ugly, but if the slide renders before the tab is active, ie when the <ProductDetails> component renders
		// the slider does not know what size to make the slides, update forces a recheck
		useEffect(() => {
			if (instanceRef.current && isVisible) {
				setTimeout(() => {
					instanceRef.current?.update();
				}, 50);
			}
		}, [isVisible, instanceRef]);

		const { review } =
			images.find((img) => img.id === selectedReviewImageId) ?? {};

		const shouldShowArrows =
			isLoaded &&
			instanceRef &&
			instanceRef.current &&
			instanceRef.current.slides.length > 1;

		return (
			<div
				ref={ref}
				className={clsx(
					className,
					'relative flex h-full grow max-md:flex-col max-md:overflow-y-auto',
				)}
			>
				{shouldShowArrows && (
					<IconButton
						className="mx-6 self-center bg-julaRed max-md:hidden"
						hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
						icon="arrow"
						iconColor="white"
						iconDirection="left"
						text={t('slider_prev_button')}
						onClick={() => {
							instanceRef.current?.prev();
						}}
					/>
				)}
				<div
					className={clsx(
						'flex grow bg-black py-12 max-md:relative max-sm:max-h-80 md:min-h-0 md:min-w-0',
						!shouldShowArrows && 'md:ml-20',
					)}
				>
					<div className="flex min-w-0 flex-col">
						<div
							ref={isVisible ? sliderRef : undefined}
							className="keen-slider h-full"
						>
							{images?.map((reviewImage) => (
								<div
									key={reviewImage.image.large}
									className="keen-slider__slide flex justify-center"
								>
									<Img
										className=" object-contain"
										src={reviewImage.image.large}
										width={reviewImage.image.largeWidth}
										height={reviewImage.image.largeHeight}
									/>
								</div>
							))}
						</div>
						{isLoaded && (
							<p className="mt-2 text-center text-white">{`${currentIndex + 1}/${instanceRef.current?.slides.length}`}</p>
						)}
					</div>
					{shouldShowArrows && (
						<IconButton
							className="absolute left-2 top-1/2 -translate-y-1/2 bg-julaRed md:hidden"
							hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
							icon="arrow"
							iconColor="white"
							iconDirection="left"
							text={t('slider_prev_button')}
							onClick={() => {
								instanceRef.current?.prev();
							}}
						/>
					)}
					{shouldShowArrows && (
						<IconButton
							className="absolute right-2 top-1/2 -translate-y-1/2 bg-julaRed md:hidden"
							hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
							icon="arrow"
							iconColor="white"
							iconDirection="right"
							text={t('slider_next_button')}
							onClick={() => {
								instanceRef.current?.next();
							}}
						/>
					)}
				</div>
				{review && (
					<Review
						className={cn(
							'mx-4 shrink-0 max-md:mt-4 max-sm:mb-24 md:ml-12 md:mr-0 md:w-1/6 xl:w-1/4',
							!shouldShowArrows && 'md:mr-20',
						)}
						author={review.author}
						cons={review.cons}
						date={review.date}
						extract={review.extract}
						feedbackUrl={review.feedbackUrl}
						original={review.original}
						pros={review.pros}
						responses={review.responses}
						questions={review.questions}
						score={review.score}
						votesDown={review.votesDown}
						votesUp={review.votesUp}
						recommended={review.recommended}
						lang={review.lang}
						market={review.market}
					/>
				)}
				{shouldShowArrows && (
					<IconButton
						className="mx-6 self-center bg-julaRed max-md:hidden"
						hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
						icon="arrow"
						iconColor="white"
						iconDirection="right"
						text={t('slider_next_button')}
						onClick={() => {
							instanceRef.current?.next();
						}}
					/>
				)}
			</div>
		);
	},
);
ReviewImagesView.displayName = 'ImageLayouver_ReviewImagesView';
export default ReviewImagesView;
