/**
 * SearchCombobox
 */

import React, { useCallback, useRef, useState } from 'react';
import type {
	ComponentType,
	FocusEventHandler,
	FormEventHandler,
	KeyboardEventHandler,
	MouseEventHandler,
} from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import SearchField from 'components/SearchField';
import { useCombobox, useDebounce, useOutsideClick, useRefSetter } from 'hooks';
import type { HTMLAttributes } from 'types';
import cn from 'utils/cn';
import { getTestDataAttrFrom, ignorePromiseRejection, is } from 'utils/helpers';

export interface SearchComboboxChildProps {
	/** ID used on the search input field. */
	baseId: string;

	/** Blur the search input field. */
	blurInput: () => void;

	/** Close the combobox dropdown. */
	closeCombobox: () => void;

	/** Move focus after the search form and its children. */
	focusAfterSearch: () => void;

	/** Focus the search input field. */
	focusInput: () => void;

	/** If the combobox dropdown has been opened at least once. */
	hasOpened: boolean;

	/** If the search field has enough text to trigger a search. */
	hasSearchQuery: boolean;

	/** If the combobox dropdown is currently open. */
	isOpen: boolean;

	/** Attributes to add to a div surrounding the combobox options. */
	listboxProps: HTMLAttributes<HTMLDivElement>;

	/** The raw search input text. */
	rawInputValue: string;

	/** The currently active search query. */
	searchQuery: string;

	/** ID of the currently selected combobox option, used for ComboboxOption component. */
	selectedOptionId: string;

	/** Set the search input value. */
	setInputValue: (value: string) => void;

	/** Hook that runs a callback on form submit. */
	useFormSubmit: (handler: () => unknown) => void;
}

interface Props {
	/** Component to render after the entire combobox. */
	afterComboboxComponent?: ComponentType<SearchComboboxChildProps>;

	/** Component to render after the submit button. */
	afterSubmitComponent?: ComponentType<SearchComboboxChildProps>;

	/** Always show input clear button (when there is text) even if not open. */
	alwaysShowClearButton?: boolean;

	/** Container class name. */
	className?: string;

	/** Class name for the element containing the form fields. */
	controlsContainerClassName?: string;

	/** Class name for the form element. */
	formClassName?: string;

	/** Search field id. */
	id: string;

	/** Search field label. */
	inputLabel: string;

	/** Search field placeholder. */
	inputPlaceholder?: string;

	/** Search form submit handler. */
	onFormSubmit?: FormEventHandler<HTMLFormElement>;

	/** Container class name set only when the combobox is open. */
	openClassName?: string;

	/** Component to render for the search results. */
	resultsComponent: ComponentType<SearchComboboxChildProps>;

	/** Class name for the element containing the results component. */
	resultsContainerClassName?: string;

	/** Minimum number of characters required for a search. */
	searchQueryMinLength?: number;

	/** Form submit button label. */
	submitButtonLabel: string;
}

/**
 * A search field implementing the combobox pattern.
 *
 * - https://www.w3.org/WAI/ARIA/apg/patterns/combobox/
 * - https://www.w3.org/WAI/ARIA/apg/patterns/listbox/examples/listbox-grouped/
 *
 * The supplied results component should render ComboboxOption wrapped in a
 * listbox, possibly with ComboboxOptionGroup.
 *
 * @example
 *
 * function MyResults(props: SearchComboboxChildProps) {
 *   const results = search('hammer');
 *   return (
 *     <div {...props.listboxProps}>
 *       <ComboboxOptionGroup>
 *         {results.map((result) => (
 *           <ComboboxOption>{...}</ComboboxOption>
 *         ))}
 *       </ComboboxOptionGroup>
 *     </div>
 *   );
 * }
 *
 * function MySearch() {
 *   return <SearchCombobox resultsComponent={MyResults} />;
 * }
 */
export default function SearchCombobox({
	afterComboboxComponent: AfterComboboxComponent,
	afterSubmitComponent: AfterSubmitComponent,
	alwaysShowClearButton,
	className,
	controlsContainerClassName,
	formClassName,
	id,
	inputLabel,
	inputPlaceholder,
	onFormSubmit,
	openClassName,
	resultsComponent: ResultsComponent,
	resultsContainerClassName,
	searchQueryMinLength = 3,
	submitButtonLabel,
}: Props) {
	const router = useRouter();

	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const afterFormRef = useRef<HTMLDivElement>(null);

	const [hasOpened, setHasOpened] = useState(false);
	const onComboboxOpen = useCallback(() => {
		if (!hasOpened) {
			setHasOpened(true);
		}
	}, [hasOpened]);

	const onComboboxOptionSelect = useCallback(
		(option: HTMLElement) => {
			const link = option.querySelector<HTMLAnchorElement>('[href]');
			if (link?.href) {
				ignorePromiseRejection(router.push(link.href));
			}
		},
		[router],
	);

	const {
		isOpen,
		inputValue,
		setInputValue,
		clearSelectedOption,
		selectedOptionId,
		inputProps,
		listboxProps,
		closeCombobox,
	} = useCombobox<HTMLDivElement>({
		baseId: id,
		initialInputValue: is.string(router.query?.query) ? router.query.query : '',
		onOpen: onComboboxOpen,
		onOptionSelect: onComboboxOptionSelect,
	});

	const searchQuery = useDebounce(inputValue, 300);
	// Also check the raw input value to bypass the debounce when the search field
	// is cleared. Only check length 0 for that to avoid flipping to false while
	// replacing the query, e.g.:
	// - Focus the field, it's false.
	// - Search for 'lamps', it's true.
	// - Select the 'lamps' text and start writing 'hammers'.
	// - With a min length check of 3, it would flip back to false while the text
	//   is 'ha', then true again for 'ham' and onwards. This could cause some
	//   flickering layout if the true/false state determines what to render.
	const hasActiveSearchQuery =
		searchQuery.length >= searchQueryMinLength && inputValue.length > 0;

	// Close search dropdown on click outside
	useOutsideClick(containerRef, () => {
		closeCombobox();
	});

	const focusInput = useCallback(() => {
		inputRef.current?.focus();
	}, []);
	const blurInput = useCallback(() => {
		inputRef.current?.blur();
	}, []);
	const focusAfterSearch = useCallback(() => {
		afterFormRef.current?.focus();
	}, []);

	// Close search dropdown when focus leaves the form (e.g. tabbing away)
	const handleFormBlur: FocusEventHandler<HTMLFormElement> = (e) => {
		if (
			is.element(e.relatedTarget) &&
			is.element(e.currentTarget) &&
			!e.currentTarget.contains(e.relatedTarget)
		) {
			closeCombobox();
			blurInput();
		}
	};

	// Clear selection and send focus to input on Escape press.
	const handleFormKeyDown: KeyboardEventHandler<HTMLFormElement> = (e) => {
		if (e.key === 'Escape') {
			clearSelectedOption();
			// Leave focus on the submit button if pressing escape there.
			if (
				is.instance(e.target, HTMLButtonElement) &&
				e.target.type === 'submit'
			) {
				closeCombobox();
			} else {
				focusInput();
			}
		}
	};

	// Pretty awkward, but need to bind form submit in this component while any
	// logic for that handler must be available in ResultsComponent.
	// Expose it to the children as a hook.
	const [getFormSubmitHandler, useFormSubmit] = useRefSetter<() => unknown>();
	const handleFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		onFormSubmit?.(e);
		getFormSubmitHandler()?.();
	};

	const handleInputClearClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		setInputValue('');
		// Not focusing the input when clicking the clear button with a mouse
		// while the combobox is closed.
		const isKeyboard = !e.screenX && !e.screenY;
		if (isOpen || isKeyboard) {
			focusInput();
		}
	};

	const hasInputClearButton = alwaysShowClearButton
		? inputValue.length > 0
		: isOpen && inputValue.length > 0;

	const childProps: SearchComboboxChildProps = {
		baseId: id,
		blurInput,
		closeCombobox,
		focusAfterSearch,
		focusInput,
		hasOpened,
		hasSearchQuery: hasActiveSearchQuery,
		isOpen,
		listboxProps,
		rawInputValue: inputValue,
		searchQuery: hasActiveSearchQuery ? searchQuery : '',
		selectedOptionId,
		setInputValue,
		useFormSubmit,
	};

	return (
		<>
			<div
				ref={containerRef}
				className={clsx(className, isOpen && openClassName)}
			>
				{/* This keydown event captures bubbling escape presses from interactive elements. */}
				{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
				<form
					onBlur={handleFormBlur}
					onKeyDown={handleFormKeyDown}
					onSubmit={handleFormSubmit}
					className={cn('relative flex w-full', formClassName)}
				>
					<div className={cn('z-2 flex w-full', controlsContainerClassName)}>
						<SearchField
							handleInputClearClick={handleInputClearClick}
							hasInputClearButton={hasInputClearButton}
							id={id}
							ref={inputRef}
							inputLabel={inputLabel}
							placeholder={inputPlaceholder}
							submitButtonLabel={submitButtonLabel}
							data-cy={getTestDataAttrFrom('search-field')}
							{...inputProps}
						/>
						{is.truthy(AfterSubmitComponent) && (
							<AfterSubmitComponent {...childProps} />
						)}
					</div>

					<div
						className={cn(
							'absolute -inset-2 bottom-auto z-1',
							'overflow-y-auto overscroll-contain',
							'px-2 pb-2 pt-14',
							'bg-white',
							'rounded-md',
							'shadow',
							resultsContainerClassName,
							!isOpen && 'hidden',
						)}
					>
						<ResultsComponent {...childProps} />
					</div>
				</form>

				<div ref={afterFormRef} tabIndex={-1} className="outline-none" />
			</div>

			{is.truthy(AfterComboboxComponent) && (
				<AfterComboboxComponent {...childProps} />
			)}
		</>
	);
}
SearchCombobox.displayName = 'SearchCombobox';
