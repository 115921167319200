import { useEffect, useMemo, useState } from 'react';

import { useFilteredReviews } from 'hooks';
import type { Review, ReviewImage as Image } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';

export default function useReviewImage(productId: string) {
	const [selectedReviewImageId, setSelectedReviewImageId] = useState<
		ImageId | undefined
	>();

	const { reviewResponse, isLoading, error } = useFilteredReviews(
		productId,
		'HasImage',
	);

	const reviewImages: ReviewImage[] = useMemo(
		() =>
			filterTruthy(reviewResponse?.reviews, 'images').flatMap((review) =>
				review?.images.map((image, index) => ({
					image: { ...image },
					review,
					id: `${review.id}-${index}`,
				})),
			),
		[reviewResponse],
	);

	useEffect(() => {
		if (is.arrayWithLength(reviewImages)) {
			setSelectedReviewImageId(reviewImages[0]?.id);
		}
	}, [reviewImages]);

	return {
		reviewImages,
		selectedReviewImageId,
		setSelectedReviewImageId,
		isLoading,
		error,
	};
}
export type ImageId = `${string}-${number}`;

/** A review image with a reference to the review it belongs to. */
export interface ReviewImage {
	id: ImageId;
	image: Image;
	review: Review;
}
