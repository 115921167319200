import { useCallback, useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import { useFileUpload } from 'hooks';
import {
	CreateCustomizationRequest,
	CreateCustomizationRequestItem,
	CustomizationPlacement,
	ProductCustomizationResponse,
} from 'models/api/ProductCustomization';
import { StoredFileResponse } from 'models/api/userFileStorage';
import { ActionButtonState } from 'state-machines/ActionButton.machine';
import fetchData, { API_URL, FormattedValidationErrors } from 'utils/fetchData';
import fetchResult from 'utils/fetchResult';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { createUrl } from 'utils/url';

const fileStorageApiUrl = `${API_URL}UserFileStorage/ProductCustomization`;
const LATEST_PRINT_CONFIG_ID = 'latestPrintConfigId';

function mapCreateCustomizationRequestItems(
	printPlacements: CustomizationPlacement[] | undefined,
): CreateCustomizationRequestItem[] {
	return (
		printPlacements?.map((printPlacement) => ({
			placementId: printPlacement.id,
			optionKey: printPlacement.option?.key,
			printImageFilename: printPlacement.printImageFilename?.fileName,
		})) ?? []
	);
}
function useAddPrintPlacement(url: string) {
	const [totalCost, setTotalCost] = useState<string | undefined>(undefined);
	const [currentPrintPlacements, setPrintPlacements] = useState<
		CustomizationPlacement[] | undefined
	>();
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState<FormattedValidationErrors | undefined>();
	const hasErrors = Boolean(errors);

	const requestPrintPlacementUpdate = useCallback(
		async (printPlacements: CreateCustomizationRequest) => {
			setIsLoading(true);
			const res = await fetchResult<ProductCustomizationResponse>(url, {
				method: 'POST',
				body: JSON.stringify(printPlacements),
			});
			setIsLoading(false);
			if (res.isErr()) {
				setErrors(res.error);
				return;
			}
			if (res.isOk()) {
				setPrintPlacements(res.value.customization.placements);
				setTotalCost(res.value.customization.price?.priceIncVat?.displayValue);
				localStorage.setItem(
					LATEST_PRINT_CONFIG_ID,
					res.value.customization.id,
				);
			}
		},
		[url],
	);

	const addPrintPlacement = useCallback(
		(printPlacement: CreateCustomizationRequestItem) => {
			ignorePromiseRejection(
				requestPrintPlacementUpdate([
					...mapCreateCustomizationRequestItems(currentPrintPlacements),
					printPlacement,
				]),
			);
		},
		[currentPrintPlacements, requestPrintPlacementUpdate],
	);

	const removePrintPlacement = useCallback(
		(placementId: string) => {
			ignorePromiseRejection(
				requestPrintPlacementUpdate(
					mapCreateCustomizationRequestItems(
						currentPrintPlacements?.filter(
							(placement) => placement.id !== placementId,
						),
					),
				),
			);
		},
		[currentPrintPlacements, requestPrintPlacementUpdate],
	);

	return {
		addPrintPlacement,
		removePrintPlacement,
		printPlacements: currentPrintPlacements,
		totalCost,
		loadingState: isLoading
			? 'loading'
			: hasErrors
				? 'failure'
				: ('success' as ActionButtonState),
		errors,
	};
}

export default function useProductPrint({
	id,
	isActive,
}: {
	id: string;
	isActive: boolean;
}) {
	const [print, setPrint] = useState<StoredFileResponse | undefined>(undefined);
	const [latestPrintConfigId, setLatestPrintConfigId] = useState<
		string | null | undefined
	>(undefined);
	const hasCheckedLatestPrintConfigId =
		is.string(latestPrintConfigId) || is.null(latestPrintConfigId);
	const printCustomizationApiUrl = `${API_URL}ProductCustomization/${id}`;

	useEffect(() => {
		setLatestPrintConfigId(localStorage.getItem(LATEST_PRINT_CONFIG_ID));
	}, []);

	// Get print customization for product with possible existing print placements
	const { data: productCustomization, error: productCustomizationError } =
		useSWRImmutable(
			isActive && hasCheckedLatestPrintConfigId
				? createUrl(printCustomizationApiUrl, {
						customizationId: latestPrintConfigId,
					})
				: null,
			fetchData<ProductCustomizationResponse>,
		);

	// Print upload
	const {
		uploadedFiles,
		uploadFile,
		isLoading: fileUploadIsLoading,
		hasError: hasFileUploadErrors,
		errors: fileUploadErrors,
	} = useFileUpload({
		url: fileStorageApiUrl,
	});

	useEffect(() => {
		if (uploadedFiles.length > 0) {
			setPrint(uploadedFiles.at(0));
		}
	}, [uploadedFiles]);

	// Get possible stored prints

	const { data: storedPrints } = useSWRImmutable(
		isActive ? fileStorageApiUrl : null,
		fetchData<StoredFileResponse[]>,
	);

	const reusePrint = useCallback(
		(printId: string | undefined) => {
			const existingPrint = storedPrints?.find(
				(file) => file.fileName === printId,
			);
			if (existingPrint) {
				setPrint(existingPrint);
			}
		},
		[storedPrints],
	);

	const removePrint = useCallback(() => {
		setPrint(undefined);
	}, []);

	const {
		addPrintPlacement,
		removePrintPlacement,
		totalCost,
		printPlacements,
		loadingState: addPrintPlacementButtonState,
		errors: addPrintPlacementErrors,
	} = useAddPrintPlacement(printCustomizationApiUrl);

	return {
		isInitialLoading: !productCustomization && !productCustomizationError,
		hasError: Boolean(productCustomizationError),
		uploadPrint: uploadFile,
		print,
		storedPrints,
		existingPrintPlacements: productCustomization?.customization.placements,
		addPrintPlacementButtonState,
		addPrintPlacementErrors,
		printUploadButtonState: (fileUploadIsLoading
			? 'loading'
			: hasFileUploadErrors
				? 'failure'
				: 'success') as ActionButtonState,
		printUploadErrors: fileUploadErrors,
		addPrintPlacement,
		removePrintPlacement,
		reusePrint,
		removePrint,
		placements:
			productCustomization?.variantCustomization.printOnClothes.placements,
		printPlacements,
		totalCost,
	};
}
