import React, {
	type ChangeEventHandler,
	type KeyboardEventHandler,
	useId,
	useRef,
	useState,
} from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import DisclosureButton from 'components/DisclosureButton';
import { FAQNoResultsText } from 'components/FAQSearch';
import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import SearchField from 'components/SearchField';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { useDebounce, useFaqSearch } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		initialSearchTerm?: Field<string>;
		placeholder?: Field<string>;
	};
};

export default function FAQSupport({ params, fields }: Props) {
	const { t } = useI18n();
	const id = useId();
	const baseId = `faq-support-${id}`;
	const searchFieldRef = useRef<HTMLInputElement>(null);
	const initialTerm = fields?.initialSearchTerm?.value || '';
	const [inputValue, setInputValue] = useState<string>('');

	const searchQuery = useDebounce(inputValue, 300);
	// Also check the raw input value to bypass the debounce when the
	// search field is cleared.
	const hasSearchQuery = searchQuery.length >= 3 && inputValue.length > 0;
	const { items: faqItems, isLoading } = useFaqSearch(
		hasSearchQuery ? searchQuery : initialTerm,
		{ includeContent: true, limit: 3 },
	);
	const hasResults = faqItems.length > 0;
	const showResults = !isLoading && hasResults;
	const showNoResults = hasSearchQuery && !isLoading && !hasResults;

	const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		setInputValue(e.target.value);
	};
	const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === 'Escape') {
			setInputValue('');
		}
	};
	const handleInputClearClick = () => {
		setInputValue('');
		searchFieldRef.current?.focus();
	};

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withPopoverLayout
		>
			<SearchField
				ref={searchFieldRef}
				id={`${baseId}-search`}
				value={inputValue || ''}
				placeholder={fields?.placeholder?.value}
				onChange={handleInputChange}
				onKeyDown={handleInputKeyDown}
				handleInputClearClick={handleInputClearClick}
				inputLabel={t('faq_search_input_label')}
				submitButtonLabel={t('search_show_all_button')}
			/>
			<div className="mt-6">
				<FAQNoResultsText show={showNoResults} query={searchQuery} />
				{isLoading && (
					<Skeleton className="space-y-4">
						<SkeletonItem height="1.5rem" width="90%" />
						<SkeletonItem height="1.5rem" width="70%" />
						<SkeletonItem height="1.5rem" width="85%" />
					</Skeleton>
				)}
				{showResults && (
					<ul className="space-y-4">
						{faqItems.map((item) => (
							<li key={item.summary}>
								<DisclosureButton label={item.summary} insetChildren>
									<RichText html={item.content} className="mt-2" />
								</DisclosureButton>
							</li>
						))}
					</ul>
				)}
			</div>
		</LayoutContainer>
	);
}
FAQSupport.displayName = 'FAQSupport';
