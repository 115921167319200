import React, { useEffect } from 'react';
import {
	ComponentPropsContext,
	handleEditorFastRefresh,
	SitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import cookie from 'cookie';
import { GetServerSideProps } from 'next';

import ErrorBoundary from 'components/ErrorBoundary';
import GlobalPopover from 'components/GlobalPopover';
import PageOverlay from 'components/PageOverlay';
import { PortalContainers } from 'components/Portal';
import Toast from 'components/Toast';
import UserInformationValidationPopover from 'components/UserInformationValidationPopover';
import { publicRuntimeConfig } from 'config';
import { getLayout, TemplateNames } from 'layouts/index';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { componentBuilder } from 'temp/componentBuilder';
import type { DecodedUserJWTToken } from 'types';
import { is } from 'utils/helpers';
import jwtDecode from 'utils/jwtDecode';

// This function gets called at request time on server-side.
export const getServerSideProps: GetServerSideProps = async (context) => {
	// TODO: headers are unused?
	const headers: any = {};
	const cookies = cookie.parse(context?.req?.headers?.cookie || '');
	const authCookieName =
		publicRuntimeConfig?.NEXT_PUBLIC_AUTH_TOKEN_COOKIE_NAME;

	if (authCookieName && cookies[authCookieName]) {
		headers.Authentication = cookies[authCookieName];
	}

	const props = await sitecorePagePropsFactory.create(context);
	const jwtToken = authCookieName ? cookies[authCookieName] : '';

	// notFound has been observed as undefined sometimes even if it isn't typed
	// that way, which leads to error.
	props.notFound = props.notFound || false;

	if (jwtToken) {
		const jwtData = jwtDecode<DecodedUserJWTToken>(jwtToken);
		if (
			jwtData?.customer_type_specific &&
			jwtData.sub_type !== 'anonymous-customer-user'
		) {
			props.userInfo = {
				loginMethod: jwtData.idp,
				userType: jwtData.customer_type_specific,
			};
		}
	}

	// TODO: We don't get sitecoreContext as a prop here any longer, verify that this works instead.
	const redirect = props.layoutData.sitecore.context?.redirect;
	if (
		is.object(redirect) &&
		'location' in redirect &&
		'statusCode' in redirect
	) {
		return {
			props,
			redirect: {
				destination: redirect.location,
				statusCode: redirect.statusCode,
			},
		};
	}

	return { props };
};

export default function SitecorePage({
	notFound,
	componentProps,
	layoutData,
	userInfo,
}: SitecorePageProps): JSX.Element {
	useEffect(() => {
		// Since Experience Editor does not support Fast Refresh need to refresh EE chromes after Fast Refresh finished
		handleEditorFastRefresh();
	}, []);

	const { route } = layoutData.sitecore;
	const isEditing = layoutData.sitecore.context.pageEditing;

	const LayoutComponent = getLayout(
		(route?.templateName as TemplateNames) || 'Generic Page Route',
	);

	return (
		<ErrorBoundary>
			<ComponentPropsContext value={componentProps}>
				<SitecoreContext
					componentFactory={componentBuilder.getComponentFactory({ isEditing })}
					layoutData={layoutData}
				>
					<LayoutComponent layoutData={layoutData} userInfo={userInfo} />

					<GlobalPopover />
					<UserInformationValidationPopover />
					<Toast />

					<>
						{/* Everything in this fragment should probably be kept
						    in sync with StorybookWrapper. */}
						<PortalContainers />
						<PageOverlay />
					</>
				</SitecoreContext>
			</ComponentPropsContext>
		</ErrorBoundary>
	);
}
SitecorePage.displayName = 'SitecorePage';
