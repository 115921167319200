/**
 * ImageLayover
 */

import React, {
	MouseEventHandler,
	useCallback,
	useEffect,
	useRef,
} from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Img from 'components/Img';
import Tabs from 'components/Tabs';
import ThumbnailList from 'components/ThumbnailList';
import { useDialog } from 'hooks';
import { ImageId, ReviewImage } from 'hooks/product-details';
import { Thumbnail } from 'utils/business-logic';
import { afterNextPaint, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ReviewImagesView from './ReviewImagesView';

export type ImageLayoverTabs = 'product-images' | 'review-images';

interface Props {
	activeTabId: 'product-images' | 'review-images';
	carouselIndex: number;
	/** Optional image service to use */
	imageService?: 'nextjs';
	/** An array of product images */
	images: Thumbnail[];
	/** Whether the layover is open or not */
	isOpen: boolean;
	/** Function to call when the layover should be closed */
	onClose: () => void;
	/** Function to call when the layover tab should be changed */
	onTabChange: (newTabId: ImageLayoverTabs) => void;
	onThumbnailClick: (activeImageId: number) => void;
	onThumbnailSlideChange: (index: number) => void;
	/** An array of images with related review */
	reviewImages?: ReviewImage[];
	/** ID of the image that should be selected and scrolled to */
	selectedImageId: number;
	/** currently selected review image */
	selectedReviewImageId: ImageId | undefined;
	setSelectedReviewImageId: (id: ImageId) => void;
}

/** A component that displays images in a layover. */
export default function ImageLayover({
	activeTabId,
	carouselIndex,
	images,
	imageService,
	isOpen,
	onClose: onCloseProp,
	onTabChange: onTabChangeProp,
	onThumbnailClick,
	onThumbnailSlideChange,
	reviewImages,
	selectedImageId,
	selectedReviewImageId,
	setSelectedReviewImageId,
}: Props) {
	const { t } = useI18n();
	const productImagesScrollRef = useRef<HTMLDivElement>(null);
	const reviewImagesScrollRef = useRef<HTMLDivElement>(null);
	const largeImages = useRef<(HTMLDivElement | null)[]>([]);

	const scrollToImage = useCallback(
		(imageId: number) => {
			const img = largeImages.current[imageId];
			if (img && isOpen) {
				afterNextPaint(() => {
					img.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				});
				setTimeout(() => {
					img.focus();
				}, 250);
			}
		},
		[isOpen],
	);

	const onOpen = useCallback(() => {
		if (activeTabId === 'product-images') {
			if (productImagesScrollRef.current) {
				disableBodyScroll(productImagesScrollRef.current, {
					reserveScrollBarGap: true,
				});
			}
			scrollToImage(selectedImageId);
		}
		if (activeTabId === 'review-images' && reviewImagesScrollRef.current) {
			disableBodyScroll(reviewImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
	}, [activeTabId, scrollToImage, selectedImageId]);

	const onClose = useCallback(() => {
		onCloseProp();
		clearAllBodyScrollLocks();
	}, [onCloseProp]);

	const onTabChange = (tab: ImageLayoverTabs) => {
		if (tab === 'review-images' && reviewImagesScrollRef.current) {
			disableBodyScroll(reviewImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
		if (tab === 'product-images' && productImagesScrollRef.current) {
			disableBodyScroll(productImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
		onTabChangeProp(tab);
	};

	const { focusTrapEndProps, focusTrapStartProps, dialogProps, dialogRef } =
		useDialog({
			'aria-label': t('product_details_image_layover_title'),
			isOpen,
			onClose,
			onOpen,
		});

	const handleCloseButtonClick = () => {
		onClose();
	};
	// Close the popover if clicking outside of its content element.
	const handleBackgroundClick: MouseEventHandler<HTMLDivElement> = (e) => {
		// Stop all bubbling to parent popovers.
		e.stopPropagation();
		// If offsetParent is null the ref element is hidden and should be ignored.
		if (
			isOpen &&
			dialogRef.current?.offsetParent &&
			is.node(e.target) &&
			!dialogRef.current.contains(e.target)
		) {
			onClose();
		}
	};
	useEffect(() => {
		scrollToImage(selectedImageId);
	}, [scrollToImage, selectedImageId]);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div
			onClick={handleBackgroundClick}
			className={clsx(
				'fixed inset-0 z-imageLayover flex items-end justify-end bg-black/20 outline-none md:items-center md:justify-center',
				'transition-fadeTransform duration-300 ease-out',
				!isOpen && 'pointer-events-none invisible translate-y-[10%] opacity-0',
			)}
		>
			<div {...focusTrapStartProps} />

			<div
				{...dialogProps}
				className="relative flex h-5/6 w-full flex-col bg-white pt-5 md:h-full md:max-h-[calc(100%-4rem)] md:w-[calc(100%-4rem)] md:rounded-lg"
			>
				<Tabs<ImageLayoverTabs>
					tabListLabel={t('product_details_images_tab_list_label')}
					className="flex h-full flex-col"
					tabListClassName="sm:self-start mx-4 sm:min-w-[42rem]"
					activeTabId={activeTabId}
					onTabChange={onTabChange}
					items={[
						{
							id: 'product-images',
							title: t('product_details_images_tab_title'),
							contentClassName: 'flex justify-center h-full overflow-hidden',
							content: (
								<>
									{images.length > 0 && (
										<div className="ml-6 flex flex-col justify-center justify-self-start py-4 max-sm:hidden">
											<ThumbnailList
												images={images}
												selectedImageId={selectedImageId}
												onImageClick={onThumbnailClick}
												carouselIndex={carouselIndex}
												onSlideChange={onThumbnailSlideChange}
											/>
										</div>
									)}

									<div className="relative flex h-full grow">
										<div
											ref={productImagesScrollRef}
											className="flex grow flex-col items-center overflow-y-auto"
										>
											{images.map((image) => (
												<div
													key={image.src}
													tabIndex={-1}
													ref={(element) => {
														if (element) {
															largeImages.current[image.id] = element;
														}
													}}
												>
													<Img
														src={image.src}
														alt={image.alt}
														service={imageService}
														width={792}
														height={792}
													/>
												</div>
											))}
										</div>
										<div className="absolute bottom-0 h-24 w-full bg-gradient-to-t from-white" />
									</div>
								</>
							),
						},
						is.arrayWithLength(reviewImages) && {
							id: 'review-images',
							title: t('product_details_review_images_tab_title'),
							contentClassName: 'h-full min-h-0 min-w-0',
							content: (
								<ReviewImagesView
									ref={reviewImagesScrollRef}
									images={reviewImages}
									isVisible={activeTabId === 'review-images' && isOpen}
									selectedReviewImageId={selectedReviewImageId}
									setSelectedReviewImageId={setSelectedReviewImageId}
								/>
							),
						},
					]}
				/>

				<button
					type="button"
					onClick={handleCloseButtonClick}
					aria-label={t('product_details_close_image_layover_button')}
					className={clsx(
						'group fixed flex items-center',
						'max-sm:bottom-6 max-sm:left-1/2 max-sm:-translate-x-1/2',
						'sm:absolute sm:right-6 sm:top-6',
					)}
				>
					<span className="mr-3 font-bold group-hover:underline max-sm:hidden">
						{t('product_details_close_image_layover_button')}
					</span>
					<span className="flex h-12 w-12 items-center justify-center rounded-full bg-julaRed group-hover:bg-julaRedDark">
						<Icon icon="close" color="white" />
					</span>
				</button>
			</div>

			<div {...focusTrapEndProps} />
		</div>
	);
}
ImageLayover.displayName = 'ImageLayover';
