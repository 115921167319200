/**
 * PriceRow
 */

import React, { ReactNode } from 'react';
import clsx from 'clsx';

import Text from 'components/Text';
import { formatPriceText } from 'utils/price';

interface Props {
	children?: ReactNode;
	price?: string;
	priceSymbol?: string;
	subtitle?: string;
	title?: string;
	variant?: 'minusPrice' | 'regular';
}

export default function PriceRow({
	variant = 'regular',
	title,
	price,
	priceSymbol,
	subtitle,
	children,
}: Props) {
	return (
		<div className="mb-4 flex break-inside-avoid flex-col justify-between">
			<div className="flex justify-between">
				<Text as="p">{title}</Text>
				<Text
					as="span"
					className={clsx(
						'font-bold',
						variant === 'minusPrice' ? ' text-julaRed' : 'text-greyDarker',
					)}
				>
					{formatPriceText(price, priceSymbol)}
				</Text>
			</div>
			<div className="ml-4">
				<Text as="pSmall" className="text-greyDarker">
					{subtitle}
				</Text>
				{children}
			</div>
		</div>
	);
}
PriceRow.displayName = 'PriceRow';
