import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import {
	CreateCustomizationRequestItem,
	CustomizationPlacement,
	Option,
	Placement,
} from 'models/api/ProductCustomization';
import { StoredFileResponse } from 'models/api/userFileStorage';
import { PrintOnClothes } from 'models/product';
import { ActionButtonState } from 'state-machines/ActionButton.machine';
import { FormattedValidationErrors } from 'utils/fetchData';
import { is } from 'utils/helpers';

import PrintPlacement from './PrintPlacement';
import ProductPrintPopover from './ProductPrintPopover';

interface Props {
	addPrintPlacementButtonState: ActionButtonState;
	addPrintPlacementErrors: FormattedValidationErrors | undefined;
	className?: string;
	existingPrintPlacements: CustomizationPlacement[] | undefined;
	fileUploadButtonState: ActionButtonState;
	hasError: boolean;
	isLoading: boolean;
	onAddPrintPlacementClick: (newPrint: CreateCustomizationRequestItem) => void;
	onRemovePrintClick: (id: string) => void;
	onRemovePrintPlacementClick: (identifier: string) => void;
	onReusePrintClick: (id: string) => void;
	onSelectExistingPrintPlacementClick: (
		newPrint: CreateCustomizationRequestItem,
	) => void;
	onUpLoadPrint: (file: File) => void;
	placements: Placement[] | undefined;
	print: StoredFileResponse | undefined;
	printOnClothes: PrintOnClothes;
	printPlacements: CustomizationPlacement[] | undefined;
	printUploadErrors: string[] | undefined;
	storedPrints: StoredFileResponse[] | undefined;
	total: string | undefined;
}

export function ProductPrint({
	addPrintPlacementButtonState,
	addPrintPlacementErrors: currentAddPrintPlacementErrors,
	className,
	existingPrintPlacements,
	fileUploadButtonState,
	onAddPrintPlacementClick,
	onRemovePrintClick,
	onRemovePrintPlacementClick,
	onReusePrintClick,
	onSelectExistingPrintPlacementClick,
	onUpLoadPrint,
	placements,
	print,
	printOnClothes,
	printPlacements,
	printUploadErrors: currentPrintUploadErrors,
	storedPrints,
	total,
}: Omit<Props, 'hasError' | 'isLoading'>) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [currentPageIndex, setCurrentPageIndex] = useState(
		is.arrayWithLength(existingPrintPlacements) ? 3 : 0,
	);

	const [selectedSize, setSelectedSize] = useState<Option>();
	const [selectedPlacement, setSelectedPlacement] = useState<Placement>();

	const [printUploadErrors, setPrintUploadErrors] = useState<string[]>();
	const [addPrintPlacementErrors, setAddPrintPlacementErrors] = useState<
		FormattedValidationErrors | undefined
	>();

	const reset = useCallback(() => {
		setSelectedPlacement(undefined);
		setSelectedSize(undefined);
		setIsPopoverOpen(false);
		onRemovePrintClick('');
		setPrintUploadErrors(undefined);
		setAddPrintPlacementErrors(undefined);
		setCurrentPageIndex(is.arrayWithLength(existingPrintPlacements) ? 3 : 0);
	}, [existingPrintPlacements, onRemovePrintClick]);

	useEffect(() => {
		setPrintUploadErrors(currentPrintUploadErrors);
	}, [currentPrintUploadErrors]);

	useEffect(() => {
		setAddPrintPlacementErrors(currentAddPrintPlacementErrors);
	}, [currentAddPrintPlacementErrors]);

	useEffect(() => {
		if (addPrintPlacementButtonState === 'success') {
			reset();
		}
	}, [addPrintPlacementButtonState, reset]);

	const {
		addPrintDescription,
		addPrintHeading,
		addPrintPrimaryButton,
		addPrintSecondaryButton,
		addedPrintsLabel,
		placementDisabled,
		placementHeading,
		popoverCancel,
		popoverName,
		removePrintButton,
		reuseHeading,
		reuseLabel,
		reuseSubmitButton,
		sizeHeading,
		totalPriceLabel,
		uploadDescription,
		uploadHeading,
		uploadPriceLabel,
		uploadPrintButton,
		uploadRemoveButton,
		uploadReuseButton,
		uploadReuseLabel,
		uploadSubmitButton,
		reusePrintButton,
		uploadWarning,
	} = printOnClothes;

	return (
		<div className={className}>
			<Text as="p" field={addPrintHeading} className="font-bold" />
			<RichText field={addPrintDescription} />

			{is.arrayWithLength(printPlacements) && (
				<>
					<Text
						as="p"
						text={addedPrintsLabel.value}
						className="mt-8 font-bold"
					/>
					<ul className="mt-4 flex flex-col gap-2">
						{printPlacements.map((printPlacement) => (
							<PrintPlacement
								key={printPlacement.id}
								placement={printPlacement.title || ''}
								logotypeName={
									printPlacement.printImageFilename?.originalFileName
								}
								size={printPlacement.option?.text}
								price={printPlacement.option?.price?.priceIncVat}
								onClick={() => {
									onRemovePrintPlacementClick(printPlacement.id || '');
								}}
								buttonText={removePrintButton.value}
							/>
						))}
					</ul>
				</>
			)}
			<Button
				variant="text"
				onClick={() => setIsPopoverOpen(true)}
				className="group/product-print-button mt-6"
			>
				<Icon
					icon="plus"
					color="black"
					direction="up"
					backgroundColor="greyLight"
					className="group-hover/product-print-button:bg-grey"
				/>
				{is.arrayWithLength(printPlacements)
					? addPrintPrimaryButton.value
					: addPrintSecondaryButton.value}
			</Button>
			{total && (
				<Text as="p" className="mt-4">
					{totalPriceLabel.value} <span className="font-bold">{total}</span>
				</Text>
			)}
			<ProductPrintPopover
				existingPrintPlacements={existingPrintPlacements}
				onCreateNewPrintPlacementClick={() => {
					setCurrentPageIndex(0);
					setAddPrintPlacementErrors(undefined);
				}}
				onBackClick={() => {
					setCurrentPageIndex((current) => current - 1);
				}}
				onSelectExistingPrintPlacementClick={
					onSelectExistingPrintPlacementClick
				}
				isOpen={isPopoverOpen}
				currentPageIndex={currentPageIndex}
				onAddPrintPlacementClick={() => {
					if (selectedPlacement && selectedSize && print?.fileName) {
						onAddPrintPlacementClick({
							placementId: selectedPlacement.id,
							printImageFilename: print.fileName,
							optionKey: selectedSize.key,
						});
					}
				}}
				onClose={() => {
					setIsPopoverOpen(false);
					reset();
				}}
				onPlacementClick={(placement) => {
					setSelectedPlacement(placement);
					setCurrentPageIndex((current) => current + 1);
				}}
				onPrintUploadAbortClick={reset}
				onRemovePrintClick={onRemovePrintClick}
				onSizeClick={(sizeOption) => {
					setSelectedSize(sizeOption);
					setCurrentPageIndex((current) => current + 1);
				}}
				onUpLoadPrint={onUpLoadPrint}
				placements={placements?.map((placement) => ({
					...placement,
					isSelected: Boolean(
						printPlacements?.some(
							(printPlacement) => printPlacement.id === placement.id,
						),
					),
				}))}
				sizes={selectedPlacement?.options}
				print={print}
				storedPrints={storedPrints}
				onReusePrintClick={onReusePrintClick}
				fileUploadButtonState={fileUploadButtonState}
				addPrintPlacementButtonState={addPrintPlacementButtonState}
				addNewPrintPlacementButtonText={reuseSubmitButton.value}
				addPrintPlacementButtonText={uploadSubmitButton.value}
				existingPrintPlacementsHeading={reuseHeading.value}
				existingPrintPlacementsListHeading={reuseLabel.value}
				placementDisabledText={placementDisabled.value}
				placementHeading={placementHeading.value}
				popoverHeading={popoverName.value}
				selectedPlacementPrice={selectedSize?.price}
				printUploadAbortButtonText={popoverCancel.value}
				printUploadDescription={uploadDescription.value}
				printUploadHeading={uploadHeading.value}
				printUploadInfoText={uploadWarning.value}
				printUploadPriceLabel={uploadPriceLabel.value}
				removeUploadedPrintButtonText={uploadRemoveButton?.value}
				reusePrintButtonText={uploadReuseButton.value}
				reuseUploadedPrintText={uploadReuseLabel.value}
				selectExistingPrintPlacementButtonText={reusePrintButton.value}
				sizeHeading={sizeHeading.value}
				uploadButtonText={uploadPrintButton.value}
				printUploadErrors={printUploadErrors}
				addPrintPlacementErrors={addPrintPlacementErrors}
			/>
		</div>
	);
}

ProductPrint.displayName = 'ProductPrint';

export default function ProductPrintWrapper({
	addPrintPlacementButtonState,
	addPrintPlacementErrors,
	className,
	existingPrintPlacements,
	fileUploadButtonState,
	hasError,
	isLoading,
	onAddPrintPlacementClick,
	onRemovePrintClick,
	onRemovePrintPlacementClick,
	onReusePrintClick,
	onSelectExistingPrintPlacementClick,
	onUpLoadPrint,
	placements,
	print,
	printOnClothes,
	printPlacements,
	printUploadErrors,
	storedPrints,
	total,
}: Props) {
	if (isLoading) {
		return (
			<Skeleton>
				<SkeletonItem height="1rem" className="mt-4" />
				<SkeletonItem height="1rem" className="mb-8 mt-2" />
				<SkeletonItem height="1rem" className="mt-4" />
			</Skeleton>
		);
	}
	if (hasError) {
		return null;
	}
	return (
		<ProductPrint
			className={className}
			onRemovePrintPlacementClick={onRemovePrintPlacementClick}
			printPlacements={printPlacements}
			onAddPrintPlacementClick={onAddPrintPlacementClick}
			onSelectExistingPrintPlacementClick={onSelectExistingPrintPlacementClick}
			print={print}
			placements={placements}
			onUpLoadPrint={onUpLoadPrint}
			existingPrintPlacements={existingPrintPlacements}
			total={total}
			storedPrints={storedPrints}
			addPrintPlacementButtonState={addPrintPlacementButtonState}
			fileUploadButtonState={fileUploadButtonState}
			onReusePrintClick={onReusePrintClick}
			printOnClothes={printOnClothes}
			onRemovePrintClick={onRemovePrintClick}
			printUploadErrors={printUploadErrors}
			addPrintPlacementErrors={addPrintPlacementErrors}
		/>
	);
}
ProductPrintWrapper.displayName = 'ProductPrintWrapper';
