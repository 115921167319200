/**
 * BlockMedia
 */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Img from 'components/Img';
import { OptionalLink } from 'components/Link';
import Popover from 'components/Popover';
import ProductAnimation from 'components/ProductAnimationNew';
import ProductCard from 'components/ProductCard';
import VideoBackground from 'components/VideoBackground';
import {
	useIsEditing,
	usePrefersReducedMotion,
	useProductListGTMEvents,
} from 'hooks';
import type { DigizuiteAsset } from 'models/asset';
import type { PriceSize } from 'models/price';
import type { ProductCard as ProductCardModel } from 'models/productCard';
import cn from 'utils/cn';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export type BlockMediaProductsDisplayType = 'shopByMedia' | 'campaignSpinner';

type Variant =
	| 'landscape'
	| 'landscapeSmall'
	| 'landscapeTall'
	| 'landscapeWide'
	| 'portrait'
	| 'square'
	| 'squareSmall';

interface ImageSize {
	height: number;
	width: number;
}

const IMAGE_SIZES: Record<Variant, ImageSize> = {
	landscape: { width: 1088, height: 544 },
	landscapeSmall: { width: 532, height: 266 },
	landscapeTall: { width: 720, height: 540 },
	landscapeWide: { width: 1644, height: 822 },
	portrait: { width: 532, height: 709 },
	square: { width: 720, height: 720 },
	squareSmall: { width: 254, height: 254 },
} as const;

interface BaseProps {
	/** Background color for any base price text */
	basePriceTextBackground?: string;

	/** Added on top of the image before the buttons. */
	beforeButtonsContent?: React.ReactNode;

	className?: string;

	/** Link for the image or video. */
	href?: string;

	/** How the image should be loaded. */
	imageLoading?: 'eager' | 'lazy';

	/** Make the image high priority and preload it, only use when the image is visible above the fold. */
	imagePriority?: boolean;

	imgAlt?: string;

	imgClassName?: string;

	imgSrc?: string;

	/** Class name for the element wrapping the image or video. */
	mediaWrapperClassName?: string;

	preventAutoplay?: boolean;

	productAnimationPlacementClassName?: string;
	productAnimationPriceSize?: PriceSize;
	/** Image format. */
	variant: Variant;

	video?: DigizuiteAsset;
}

interface PropsWithoutProducts extends BaseProps {
	gtmItemListId?: never;
	gtmItemListName?: never;
	products?: never;
	productsDisplayType?: never;
}

interface PropsWithProducts extends BaseProps {
	/** GTM list ID for the products. */
	gtmItemListId: GTMItemListId;

	/** GTM list name for the products. */
	gtmItemListName: GTMItemListName;

	/** Products to display as a campaign spinner or 'shop by media' popover. */
	products: ProductCardModel[] | undefined;

	/** How to display the products. */
	productsDisplayType: BlockMediaProductsDisplayType;
}

type Props = PropsWithoutProducts | PropsWithProducts;

/**
 * A component that handles video, shopByMedia and campaign for blocks that
 * have it and handles logic for how and when they should be rendered
 */
export default function BlockMedia({
	basePriceTextBackground,
	className,
	gtmItemListId,
	gtmItemListName,
	href,
	imageLoading,
	imagePriority = false,
	imgAlt,
	imgClassName,
	imgSrc,
	mediaWrapperClassName,
	preventAutoplay,
	productAnimationPlacementClassName,
	productAnimationPriceSize,
	products,
	productsDisplayType,
	variant,
	video,
	beforeButtonsContent,
}: Props) {
	const { t } = useI18n();
	const isEditing = useIsEditing();
	const [isShopByMediaOpen, setIsShopByMediaOpen] = useState(false);

	// Check reduced motion in an effect since it can't be known on the server.
	const prefersReducedMotion = usePrefersReducedMotion();
	const [shouldPlay, setShouldPlay] = useState(!preventAutoplay);
	useEffect(() => {
		if (prefersReducedMotion === true) {
			setShouldPlay(false);
		}
	}, [prefersReducedMotion]);

	const hasProducts = is.arrayWithLength(products);
	const showAnimation =
		hasProducts && productsDisplayType === 'campaignSpinner' && !video;
	const showShopByMedia = hasProducts && productsDisplayType === 'shopByMedia';
	const imageSize = IMAGE_SIZES[variant];

	const hasVideoUrl = Boolean(video?.src);
	// In the editor, a video field without a video asset may get a blank image
	// instead of being empty (e.g. video.src === /blank.png). Since video has
	// priority, this will result in a broken video background showing nothing
	// at all. If the editor doesn't want a video and has only selected an image,
	// that image won't be visible. Only try to show the video in the editor if
	// there is no image selected.
	const hasVideo = isEditing ? hasVideoUrl && !imgSrc : hasVideoUrl;
	const hasMediaDescription = Boolean(hasVideo ? video?.alt : imgAlt);

	const { sendViewItemListEvent } = useProductListGTMEvents(
		gtmItemListId ?? 'block',
		gtmItemListName ?? 'Block: Unknown',
	);

	return (
		<div className={cn('relative overflow-hidden', className)}>
			<OptionalLink
				href={href}
				className={cn(
					'relative block',
					'focus-within:after:absolute',
					'focus-within:after:inset-0',
					'focus-within:after:border-2',
					'focus-within:after:border-black',
					'focus-within:after:ring-inset',
					'focus-within:after:ring-2',
					'focus-within:after:ring-white',
					mediaWrapperClassName,
				)}
				isScreenReaderHidden={!hasMediaDescription}
			>
				{hasVideo && video && (
					<VideoBackground asset={video} shouldPlay={shouldPlay} cover />
				)}
				{!hasVideo && (
					<Img
						src={imgSrc}
						alt={imgAlt}
						width={imageSize.width}
						height={imageSize.height}
						service="nextjs"
						className={imgClassName}
						priority={imagePriority}
						loading={imageLoading}
					/>
				)}
			</OptionalLink>
			{beforeButtonsContent}
			{showAnimation && (
				<div className={productAnimationPlacementClassName}>
					<ProductAnimation
						products={products}
						coverHeight
						shouldPlay={shouldPlay}
						priceSize={productAnimationPriceSize}
						basePriceTextBackground={basePriceTextBackground}
					/>
				</div>
			)}
			{(showAnimation || video) && (
				<Button
					size="small"
					rounded
					className={clsx(
						'absolute bottom-4 right-4 z-10 h-10 w-10 md:bottom-6 md:right-6',
						// Cover the entire corner it's placed in
						'after:absolute after:-inset-4 md:after:-inset-6',
					)}
					onClick={() => {
						setShouldPlay((current) => !current);
					}}
				>
					<Icon icon={shouldPlay ? 'pause' : 'play'} />
					<span className="sr-only">{t(shouldPlay ? 'pause' : 'play')}</span>
				</Button>
			)}
			{/* Unnecessary `products` check to make TS understand GTM props are defined. */}
			{showShopByMedia && products && (
				<>
					<Button
						size="small"
						rounded
						className="absolute bottom-4 left-4"
						onClick={() => setIsShopByMediaOpen(true)}
					>
						<Icon icon="cart" size={16} className="mr-2" />
						{t('product_blocks_show_products_button')}
					</Button>
					<Popover
						isOpen={isShopByMediaOpen}
						title={t('products_media_popover_heading')}
						onOpen={() => {
							sendViewItemListEvent(products, products.length);
						}}
						onClose={() => {
							setIsShopByMediaOpen(false);
						}}
					>
						{products.map((product, i) => (
							<ProductCard
								key={product.id}
								product={product}
								productListIndex={i}
								gtmItemListId={gtmItemListId}
								gtmItemListName={gtmItemListName}
								orientation="row"
								buttonText={t('product_buy_button')}
								showAddToCartButton
							/>
						))}
					</Popover>
				</>
			)}
		</div>
	);
}
BlockMedia.displayName = 'BlockMedia';
