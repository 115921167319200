import React, { useEffect, useId, useState } from 'react';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Icon from 'components/Icon';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { publicRuntimeConfig } from 'config';
import {
	useCustomerInformation,
	useGlobalLinks,
	useOrderHistoryDetails,
} from 'hooks';
import { FormattedPriceResponse } from 'models/api';
import { range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { formatPriceText } from 'utils/price';
import { createUrl, joinPath } from 'utils/url';

import OrderDetails from './OrderDetails';

interface Props {
	amountOfProducts: number;
	className?: string;
	id: string;
	images: { alt?: string; url: string }[];
	isSelectedForPrint: boolean;
	onClose?: (orderId: string) => void;
	onOpen?: (orderId: string) => void;
	onPrintClick: (orderId: string) => void;
	pointOfPurchase: string;
	purchaseDate: string;
	rendering: ComponentRendering;
	totalSum: FormattedPriceResponse;
}

export default function OrderListItem({
	amountOfProducts,
	className,
	id,
	images,
	isSelectedForPrint,
	onClose,
	onOpen,
	onPrintClick,
	pointOfPurchase,
	purchaseDate,
	rendering,
	totalSum,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const { track, accountOrders } = useGlobalLinks();
	const { t, tPlural } = useI18n();

	const { orderDetails, isLoading, error } = useOrderHistoryDetails(id, isOpen);
	const loadedAndOk = !isLoading && !error;
	const { customerInformation } = useCustomerInformation();

	const fallbackId = useId();
	const triggerId = `order-${id}` || `order-${fallbackId}`;
	const contentId = `${triggerId}-content`;

	const {
		transactionHead,
		transactionInformation,
		discounts,
		vatAmount,
		shippingCost,
		lines,
	} = orderDetails ?? {};

	const trackingUrl =
		track && transactionHead?.orderId && customerInformation?.id
			? createUrl(track, {
					id: transactionHead?.orderId,
					c: customerInformation?.id,
				})
			: undefined;

	const showContactReferences =
		publicRuntimeConfig?.NEXT_PUBLIC_SHOW_REFERENCE?.toLowerCase() === 'true';

	const handleOpenToggle = () => {
		setIsOpen((current) => !current);
	};
	useEffect(() => {
		if (isOpen) {
			onOpen?.(id);
		} else {
			onClose?.(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, isOpen]);

	return (
		<li
			className={clsx(
				'rounded-button border print:border-none print:ring-0',
				!isOpen && 'border-grey',
				isOpen && 'border-greyDarker ring-1 ring-inset ring-greyDarker',
				!isSelectedForPrint && 'print:hidden',
				className,
			)}
		>
			<Text
				as="h1"
				className={clsx(
					'hidden',
					isSelectedForPrint && isOpen && 'print:block',
				)}
			>
				{`${t('account_purchases_order')}: ${purchaseDate}`}
			</Text>
			<div
				className={clsx(
					'group/order-details',
					'relative flex w-full items-center justify-between p-4 print:p-0',
					isOpen && 'print:hidden',
				)}
			>
				<div className="flex flex-col">
					<time className="font-bold">{purchaseDate}</time>
					<p>{pointOfPurchase}</p>
					<p>{tPlural('general_amount_of_products', amountOfProducts)}</p>
					<p className="font-bold">
						{formatPriceText(totalSum.displayValue, totalSum.displaySymbol)}
					</p>
				</div>
				<div className="flex items-center gap-2">
					{images.map((image) => (
						<Img
							key={image.url}
							src={image.url}
							service="nextjs"
							width={64}
							height={64}
							alt={image.alt}
							className="h-12 w-12 rounded-md object-cover"
						/>
					))}
					<ButtonLink
						href={accountOrders ? joinPath(accountOrders, id) : ''}
						onClick={handleOpenToggle}
						className="before:absolute before:inset-0 print:hidden"
						id={triggerId}
						aria-expanded={isOpen}
						aria-controls={contentId}
						aria-label={`${t('account_purchases_order')}: ${purchaseDate}`}
					>
						<span
							className={clsx(
								'ml-4 inline-flex size-8 shrink-0 items-center justify-center rounded-full',
								!isOpen &&
									'bg-julaRed group-hover/order-details:bg-julaRedDark',
								isOpen && 'bg-greyDark group-hover/order-details:bg-greyDarker',
							)}
						>
							<Icon
								icon="arrow"
								className="transition-transform"
								color="white"
								direction={isOpen ? 'up' : 'down'}
							/>
						</span>
					</ButtonLink>
				</div>
			</div>
			{isOpen && (
				<div
					className="px-4 pb-4 print:p-0"
					id={contentId}
					role="region"
					aria-labelledby={triggerId}
				>
					{!isLoading && error && (
						<InfoBox icon="error" variant="error">
							{t('jula_pro_order_fetch_error_text')}
						</InfoBox>
					)}
					{isLoading && (
						<Skeleton>
							<SkeletonItem
								height="2rem"
								width="6rem"
								className="mt-8 rounded-full"
							/>
							{/* order info */}
							<SkeletonItem height="2rem" width="60%" className="mt-4" />
							<SkeletonItem height="1rem" width="75%" className="mt-4" />
							<SkeletonItem height="1rem" width="75%" className="mt-4" />
							<SkeletonItem height="1rem" width="75%" className="mt-4" />
							<SkeletonItem height="1rem" width="75%" className="mt-4" />
							{/* contact info */}
							<SkeletonItem height="2rem" width="45%" className="mb-6 mt-8" />
							<div className="md:flex">
								<div className="w-1/2">
									<SkeletonItem height="1.5rem" width="75%" className="mb-6" />
									<SkeletonItem height="1rem" width="75%" className="mt-4" />
									<SkeletonItem height="1rem" width="75%" className="mt-4" />
									<SkeletonItem height="1rem" width="75%" className="mt-4" />
								</div>
								<div className="w-1/2">
									<SkeletonItem height="1.5rem" width="75%" className="mb-6" />
									<SkeletonItem height="1rem" width="75%" className="mt-4" />
									<SkeletonItem height="1rem" width="75%" className="mt-4" />
									<SkeletonItem height="1rem" width="75%" className="mt-4" />
								</div>
							</div>
							<SkeletonItem
								height="1.5rem"
								width="75%"
								className="mb-6 mt-10"
							/>
							<SkeletonItem height="1rem" width="75%" className="mt-4" />
							<SkeletonItem height="1rem" width="75%" className="mt-4" />
							{/* products */}
							<SkeletonItem height="2rem" width="60%" className="mt-4" />
							<div className="mt-8 divide-y divide-greyDark border-y border-greyDark">
								{range(3).map((index) => (
									<div key={index} className="flex py-4">
										<SkeletonItem width="2rem" height="2rem" />
										<div className="ml-4 flex w-full flex-col">
											<SkeletonItem height="1.5rem" width="75%" />
											<SkeletonItem
												height="1rem"
												width="75%"
												className="mt-4"
											/>
										</div>
									</div>
								))}
							</div>
						</Skeleton>
					)}
					{loadedAndOk && (
						<>
							<Button
								size="small"
								className="mt-8 print:hidden"
								onClick={() => onPrintClick(id)}
								rounded
							>
								<Icon icon="printer" className="mr-2" />
								{t('print_button')}
							</Button>
							<OrderDetails
								className="mt-4"
								trackingUrl={trackingUrl}
								isEcomOrder={transactionHead?.type === 'EcomOrder'}
								isResursBankOrder={
									transactionHead?.primaryPaymentMethodId === 'RES'
								}
								rendering={rendering}
								showContactReferences={showContactReferences}
								lines={lines}
								shippingCost={shippingCost}
								totalSum={totalSum}
								vatAmount={vatAmount}
								/* Since this component is also used on the 'standalone' order details page
								 * and we want to move the point of purchase from the order list 'header' down
								 * into the component when printing this seemed like the more convenient solution */
								pointOfPurchase={
									isSelectedForPrint
										? transactionHead?.pointOfPurchase
										: undefined
								}
								bonusDiscount={discounts?.bonusDiscount}
								buyer={transactionInformation?.buyer}
								campaignDiscount={discounts?.campaignDiscount}
								customerDiscount={discounts?.customerDiscount}
								miscDiscount={discounts?.miscDiscount}
								deliveryAddress={transactionInformation?.deliveryAddress}
								invoiceAddress={transactionInformation?.invoiceAddress}
								deliveryMethod={transactionHead?.deliveryMethod}
								fixedReferenceText={transactionInformation?.fixedReferenceText}
								freeReferenceText={transactionInformation?.freeReferenceText}
								orderNumber={transactionHead?.orderNumber}
								primaryPaymentMethod={transactionHead?.primaryPaymentMethod}
								statusText={transactionHead?.status.text}
								phoneNumber={
									transactionInformation?.contactDetails?.phoneNumber
								}
								email={transactionInformation?.contactDetails?.email}
							/>
						</>
					)}
				</div>
			)}
		</li>
	);
}

OrderListItem.displayName = 'AccountPurchases_OrderListItem';
