/**
 * DisclosureButton
 */

import React, { ReactNode } from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';

interface Props {
	children: ReactNode;
	className?: string;
	insetChildren?: boolean;
	label: string;
	underline?: boolean;
}

/** A disclosure widget with a label an arrow that shows open and close state */
export default function DisclosureButton({
	children,
	className,
	insetChildren,
	label,
	underline,
}: Props) {
	return (
		<details
			className={clsx(
				'group/disclosure-button',
				insetChildren && 'pl-8',
				className,
			)}
		>
			<summary
				className={clsx(
					'group/disclosure-summary inline-flex cursor-pointer list-none align-top [&::-webkit-details-marker]:hidden',
					insetChildren && '-ml-8',
				)}
			>
				<Icon
					className="mr-2 group-open/disclosure-button:hidden"
					icon="arrow"
					color="white"
					direction="right"
					backgroundColor="julaRed"
				/>
				<Icon
					className="mr-2 hidden group-open/disclosure-button:inline-block"
					icon="arrow"
					color="black"
					direction="down"
					backgroundColor="greyLight"
				/>
				<span
					className={clsx(
						'mt-px',
						underline
							? 'underline group-hover/disclosure-summary:no-underline'
							: 'group-hover/disclosure-summary:underline',
					)}
				>
					{label}
				</span>
			</summary>
			{children}
		</details>
	);
}
DisclosureButton.displayName = 'DisclosureButton';
