import React, { type KeyboardEventHandler, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { PopularProductsList } from 'components/PopularProducts';
import PopularSearchTerms from 'components/PopularSearchTerms';
import {
	useDebounce,
	useForwardedRef,
	useMinWidth,
	usePopularProducts,
} from 'hooks';
import type { MainLinkFields } from 'models/pageHeader';
import type { InspirationLink } from 'models/sitecore';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import { getLinkPath, getStripeGradient, isLargeDropdown } from './helpers';
import HiddenLinkList from './HiddenLinkList';
import PageHeaderInspirationLinks from './PageHeaderInspirationLinks';
import PageHeaderInspirationMedia from './PageHeaderInspirationMedia';
import PageHeaderLinkList, {
	type LinkListTabHandler,
} from './PageHeaderLinkList';
import type { DropdownLinkClickHandler } from './PageHeaderLinkListItem';

interface Props {
	/** A unique ID for this dropdown */
	id: string;

	inspirationLink: InspirationLink | undefined;

	/** If the user is on a large screen */
	isLargeScreen: boolean | undefined;

	/** If currently open */
	isOpen?: boolean;

	/** The full link tree */
	links: MainLinkFields[];

	/* Tab key press handler for the last link in the menu when large screen dropdowns are used */
	onLargeScreenMenuLastLinkTab: KeyboardEventHandler<HTMLAnchorElement>;

	/* Click handler for links on all levels */
	onLinkClick?: DropdownLinkClickHandler;

	/* Tab key press handler for the last link in the menu when it's toggled for small screens */
	onToggledMenuLastLinkTab: KeyboardEventHandler<HTMLAnchorElement>;

	/** Currently selected link inside the dropdown, if any */
	selectedLinkId: string;

	/** Heading text */
	title?: string;
}

/** Dropdown menu content */
const PageHeaderDropdown = React.forwardRef<HTMLDivElement, Props>(
	(
		{
			id,
			inspirationLink,
			isLargeScreen,
			isOpen = false,
			links,
			onLargeScreenMenuLastLinkTab,
			onLinkClick,
			onToggledMenuLastLinkTab,
			selectedLinkId,
			title,
		},
		forwardedRef,
	) => {
		const { t } = useI18n();
		const ref = useForwardedRef<HTMLDivElement>(forwardedRef);
		const selected = useMemo(
			() => getLinkPath(selectedLinkId, links),
			[selectedLinkId, links],
		);
		const openLevels = selected.length + 1;
		// The objects are for each tree level.
		const linkRefs = [
			{
				selected: useRef<HTMLAnchorElement>(null),
				selectedSibling: useRef<HTMLAnchorElement>(null),
			},
			{
				first: useRef<HTMLAnchorElement>(null),
				selected: useRef<HTMLAnchorElement>(null),
				selectedSibling: useRef<HTMLAnchorElement>(null),
			},
			{
				first: useRef<HTMLAnchorElement>(null),
			},
		];

		const handleLinkClick: DropdownLinkClickHandler = (linkId) => {
			onLinkClick?.(linkId);
		};

		const handleSelectedLinkTab: LinkListTabHandler = (level, event) => {
			// Tab to the first link in the expanded child list.
			if (isLargeScreen && !event.shiftKey) {
				// Level = index + 1 for the next level.
				// If the first one is selected, the first ref won't be set.
				const firstChild =
					linkRefs[level]?.first?.current ?? linkRefs[level]?.selected?.current;
				if (firstChild) {
					event.preventDefault();
					firstChild.focus();
				}
			}
		};

		const handleFirstLinkTab: LinkListTabHandler = (level, event) => {
			// Tab back to the selected item in the parent list.
			if (isLargeScreen && event.shiftKey) {
				// Level - 1 for index, - 1 again for previous level.
				const selectedParent = linkRefs[level - 2]?.selected?.current;
				if (selectedParent) {
					event.preventDefault();
					selectedParent.focus();
				}
			}
		};

		const handleLastLinkTab: LinkListTabHandler = (level, event) => {
			if (event.shiftKey) {
				return;
			}
			if (isLargeScreen) {
				const hasSelected = selected.length > 0;
				const isLastLinkOnLevel1Selected =
					links.findIndex((link) => link.id === selected[0]?.id) ===
					links.length - 1;
				// If any sub levels are open, the native focus would move into those
				// and the next if branch would move it back to the parent, causing
				// focus to be stuck in a loop inside the dropdown.
				if (
					hasSelected &&
					// Tab on final top level link when it's not selected. The selected
					// link handler will handle it otherwise.
					((level === 1 && !isLastLinkOnLevel1Selected) ||
						// Tab on nested link when the final top level link is selected.
						(level === 2 && isLastLinkOnLevel1Selected))
				) {
					// The callback should move focus out of the dropdown.
					onLargeScreenMenuLastLinkTab(event);
				} else if (level > 1) {
					// Tab back to the link following the selected one in the parent list.
					// Level - 1 for index, - 1 again for previous level.
					const selectedParentSibling =
						linkRefs[level - 2]?.selectedSibling?.current;
					if (selectedParentSibling) {
						event.preventDefault();
						selectedParentSibling.focus();
					}
				}
			} else {
				onToggledMenuLastLinkTab(event);
			}
		};

		const isLargePanel = isLargeDropdown(links);
		const hasPopular = isOpen && isLargePanel;
		const linkTheme = selected[0]?.color;

		// Use debouncing for some data to take the small screen back button
		// transition into account. Otherwise props like `links` sent to
		// PageHeaderLinkList will be empty right when the back button is pressed,
		// causing the list of links to disappear while the back transition runs.
		// This makes it look like a flash of disappearing content and the menu
		// feels a bit janky.
		const transitionDuration = 150;
		const selectedDebounced = useDebounce(selected, transitionDuration);
		const isOpenDebounced = useDebounce(isOpen, transitionDuration);
		const getSelectedLink = (index: number): MainLinkFields | undefined =>
			selected[index] ?? selectedDebounced[index];
		const isLg = useMinWidth('lg');

		const { products } = usePopularProducts({
			// Don't trigger fetching when hidden.
			category: openLevels < 3 ? selectedLinkId : undefined,
			isIdle: false,
		});

		const pageHeaderInspirationLinks = selected[0]?.contentLinks;
		const hasVisiblePageHeaderInspirationLinks =
			is.arrayWithLength(pageHeaderInspirationLinks) && isLg;

		const hasVisibleLevel2PopularContent =
			openLevels === 2 &&
			(hasVisiblePageHeaderInspirationLinks || is.arrayWithLength(products));

		const contentColumnsBaseClasses =
			'absolute top-12 z-30 col-span-4 w-full px-6 lg:col-span-3';

		// On large screens the dropdown will be full width if there's more than
		// one level of links, otherwise it's a small list centered under the
		// trigger link.
		// On small screens each dropdown level is stacked on top of each other
		// inside the toggled menu panel.
		return (
			<div
				ref={ref}
				id={id}
				className={clsx(
					'absolute z-10 w-full bg-white',
					!isOpen && 'invisible',
					!isLargeScreen && [
						'bottom-0 top-0 overflow-hidden transition-toggledMenuPanel',
						!isOpen && 'translate-x-5 opacity-0 delay-closedToggledMenuPanel',
					],
					isLargeScreen && [
						'top-full mt-[1px] shadow-summaryShadow',
						isLargePanel && 'left-0 grid h-auto grid-cols-12 gap-x-6',
						!isLargePanel && 'left-1/2 w-auto min-w-[15rem] -translate-x-1/2',
					],
				)}
			>
				{/* The container is invisible when closed so the content isn't in the
				    way, but any headings inside are disliked for SEO reasons so don't
				    render anything when closed to avoid having it included in the
				    server response. */}
				{(isOpen || isOpenDebounced) && (
					<>
						{isLargePanel && (
							<div
								className="h-2 max-md:hidden md:col-span-12"
								style={{
									background:
										linkTheme ||
										getStripeGradient(
											filterTruthy(links, 'color')
												// White stops against a white background looks broken.
												.filter(
													(link) => !['#fff', '#ffffff'].includes(link.color),
												)
												.map((link) => link.color),
										),
								}}
							/>
						)}
						<PageHeaderLinkList
							level={1}
							className="md:col-span-3"
							baseId={id}
							isLargeScreen={isLargeScreen}
							openLevels={openLevels}
							links={links}
							onLastLinkTab={handleLastLinkTab}
							onLinkClick={isLargePanel ? handleLinkClick : undefined}
							onSelectedLinkTab={handleSelectedLinkTab}
							renderPopularSearchTerms={hasPopular && !isLargeScreen}
							selectedLink={selected[0]}
							selectedLinkRef={linkRefs[0]?.selected}
							selectedSiblingLinkRef={linkRefs[0]?.selectedSibling}
							title={isLargePanel ? title : undefined}
						/>
						{isLargePanel && (
							<>
								<PageHeaderLinkList
									level={2}
									className="md:col-span-3"
									baseId={id}
									parentId={selected[0]?.id}
									firstLinkRef={linkRefs[1]?.first}
									isLargeScreen={isLargeScreen}
									openLevels={openLevels}
									linkBarColor={linkTheme}
									links={getSelectedLink(0)?.links}
									onFirstLinkTab={handleFirstLinkTab}
									onLastLinkTab={handleLastLinkTab}
									hasPopularContent={hasVisibleLevel2PopularContent}
									onLinkClick={handleLinkClick}
									onSelectedLinkTab={handleSelectedLinkTab}
									selectedLink={selected[1]}
									selectedLinkRef={linkRefs[1]?.selected}
									selectedSiblingLinkRef={linkRefs[1]?.selectedSibling}
									title={getSelectedLink(0)?.text}
									viewAllHref={getSelectedLink(0)?.href}
								/>
								<PageHeaderLinkList
									level={3}
									className="md:col-span-6"
									baseId={id}
									parentId={selected[1]?.id}
									firstLinkRef={linkRefs[2]?.first}
									isLargeScreen={isLargeScreen}
									openLevels={openLevels}
									linkBarColor={linkTheme}
									links={getSelectedLink(1)?.links}
									onFirstLinkTab={handleFirstLinkTab}
									onLastLinkTab={handleLastLinkTab}
									title={getSelectedLink(1)?.text}
									viewAllHref={getSelectedLink(1)?.href}
								/>
							</>
						)}
						{hasPopular && isLargeScreen && (
							<>
								<PopularProductsList
									heading={t('search_popular_products_text')}
									products={products}
									onLastLinkTab={
										openLevels === 2 && !hasVisiblePageHeaderInspirationLinks
											? (e) => handleLastLinkTab(2, e)
											: undefined
									}
									className={clsx(
										contentColumnsBaseClasses,
										openLevels === 1 && '!col-start-4',
										openLevels === 2 && '!col-start-7',
										openLevels === 3 && '!hidden',
									)}
								/>
								<PageHeaderInspirationMedia
									inspirationLink={inspirationLink}
									className={clsx(
										contentColumnsBaseClasses,
										'col-start-8 lg:col-start-7',
										(openLevels === 2 || openLevels === 3) && '!hidden',
									)}
								/>
								<PopularSearchTerms
									heading={t('search_popular_search_terms_text')}
									variant="chips"
									itemLimit={5}
									className={clsx(
										contentColumnsBaseClasses,
										(openLevels === 2 || openLevels === 3) && '!hidden',
										inspirationLink
											? 'max-lg:hidden lg:col-start-10'
											: 'col-start-8 lg:col-start-7',
									)}
								/>
								{openLevels === 2 && hasVisiblePageHeaderInspirationLinks && (
									<PageHeaderInspirationLinks
										links={pageHeaderInspirationLinks}
										onLastLinkTab={(e) => handleLastLinkTab(2, e)}
										className={clsx(
											contentColumnsBaseClasses,
											'max-lg:hidden lg:col-start-10',
										)}
									/>
								)}
							</>
						)}
					</>
				)}
				{is.arrayWithLength(links) && (
					<HiddenLinkList links={links} level={1} />
				)}
			</div>
		);
	},
);
PageHeaderDropdown.displayName = 'PageHeaderDropdown';

export default PageHeaderDropdown;
