import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import type { Field as FieldType } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import Button from 'components/Button';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Icon from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import SearchField from 'components/SearchField';
import { useGlobalStateContext } from 'contexts';
import type { JulaComponentProps } from 'lib/component-props';
import { SitecoreLink } from 'models/link';
import type { SearchResults } from 'models/search';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import fetchData, { API_URL } from 'utils/fetchData';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		description: FieldType<string>;
		heading: FieldType<string>;
		link: FieldType<SitecoreLink>;
		placeHolderText: FieldType<string>;
	};
};

export default function FindProduct({ params, fields }: Props) {
	const { globalPopoverService } = useGlobalStateContext();
	const router = useRouter();
	const { t } = useI18n();
	const [isEmptyResult, setIsEmptyResult] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	if (!fields) {
		return null;
	}
	const buttonText = fields.link.value.text || fields.link.value.title;
	const target = fields.link.value.id;

	if (!fields.heading) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="FindProduct"
				description="Requires a heading"
			/>
		);
	}
	const onSubmit = async ({ query }: { query: string | undefined }) => {
		if (query?.length !== 6) {
			return;
		}
		setIsEmptyResult(false);
		setIsLoading(true);
		const results = await fetchData<SearchResults>(
			`${API_URL}Search?searchString=${query}&page-size=1`,
			{ method: 'POST' },
		);
		const products = filterTruthy(
			results?.productSearchResponse?.products ?? [],
			'url',
		);
		if (products.length === 1 && products[0]) {
			router.push(products[0].url);
		} else {
			setIsLoading(false);
			setIsEmptyResult(true);
		}
	};

	return (
		<LayoutContainer outerClassName={getEditorMargin(params)} withGrid>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, form }) => (
					<form
						onSubmit={handleSubmit}
						className="col-span-full mt-4 md:col-span-3"
					>
						<Field
							name="query"
							render={({ input }) => (
								<SearchField
									id="search"
									placeholder={fields?.placeHolderText?.value}
									submitButtonLabel={t('find_product_submit_button')}
									handleInputClearClick={() => {
										setIsEmptyResult(false);
										setIsLoading(false);
										form.reset();
									}}
									isLabelVisible
									inputLabel={
										<div className="mb-4">
											<span className="font-bold">{fields.heading.value}</span>{' '}
											{fields.description.value}
										</div>
									}
									inputMode="numeric"
									pattern={'\\d{6}'}
									isLoading={isLoading}
									{...input}
								/>
							)}
						/>
					</form>
				)}
			/>
			<div className="col-span-full mt-4 min-h-8">
				<p className={clsx('mb-2 text-julaRed', !isEmptyResult && 'sr-only')}>
					<Icon icon="error" className="mr-2" />
					<span aria-live="polite">
						{isEmptyResult ? t('find_product_no_results') : ''}
					</span>
				</p>
				{buttonText && target && (
					<Button
						variant="text-inline"
						className="items-center"
						onClick={() =>
							globalPopoverService.send({
								type: 'OPEN',
								target,
								heading: t('find_product_popover_heading'),
							})
						}
					>
						<Icon align="top" icon="info" color="news" className="mr-2" />
						{buttonText}
					</Button>
				)}
			</div>
		</LayoutContainer>
	);
}
FindProduct.displayName = 'FindProduct';
