/**
 * ProductDetails
 */

import React, { useEffect, useState } from 'react';
import { useInterpret, useSelector } from '@xstate/react';
import Script from 'next/script';

import ErrorBoundary from 'components/ErrorBoundary';
import ProductDetailsBlock from 'components/ProductDetails';
import {
	getDocuments,
	PRODUCT_IMPORTANT_INFO_ID,
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from 'components/ProductDetails/';
import {
	useFeatureToggle,
	useGlobalStateContext,
	useSelectedStore,
} from 'contexts';
import {
	useEffectOnce,
	useGlobalLinks,
	useHash,
	useProductListGTMEvents,
	useProductQuestions,
	useRelatedProducts,
	useReviews,
	useSitecoreContext,
} from 'hooks';
import { useProductPrint, useReviewImages } from 'hooks/product-details';
import { JulaComponentProps } from 'lib/component-props';
import { StockStore } from 'models/inventory';
import { CampaignResponse, Product } from 'models/product';
import {
	selectAdditionalSalesIsOpen,
	selectAdditionalSalesProducts,
	selectAdditionalSalesToastText,
	selectPurchaseButtonState,
} from 'state-machines/cart';
import {
	selectAllStoresStock,
	selectCurrentProductStock,
	selectCurrentStoreStock,
	selectHasWebStock,
	selectIsLoadingInitialStock,
	selectIsLoadingNearbyStoresStock,
	selectIsLoadingStock,
	selectIsLoadingVariantsStock,
	selectNearbyStoresStock,
	selectVariantsStock,
	stockMachine,
} from 'state-machines/stock';
import {
	selectWishlistAdditionalSales,
	selectWishlistAdditionalSalesIsOpen,
	selectWishlistButtonState,
} from 'state-machines/wishlist';
import {
	getBrandLogoImage,
	getProductInformationSymbols,
	getProductWarningImages,
	getVerticalEnergyLabel,
} from 'utils/business-logic';
import { pushToGTM } from 'utils/GoogleTagManager';
import { ignorePromiseRejection, is, sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	/** Product data following the product model. */
	fields: Product;
};

function ProductDetails({ fields }: Props) {
	const product = fields;
	const {
		chemicalInformation,
		hasAccessories,
		hasBelongsTo,
		hasSpareParts,
		id,
		salesAttributes,
		similarProducts,
		sizeGuideUrl,
		technicalAttributes,
		variants,
		isCustomizable,
	} = product;
	const { t } = useI18n();
	const { sitecoreContext } = useSitecoreContext();
	const { reviewPolicy } = useGlobalLinks();
	const { globalPopoverService, cartService, wishlistService } =
		useGlobalStateContext();

	// Stock is only available on the client.
	const [isStockActive, setIsStockActive] = useState(false);
	useEffectOnce(() => {
		setIsStockActive(true);
	});

	const [isAboutBrandOpen, setIsAboutBrandOpen] = useState(false);
	const [isSparePartsOpen, setIsSparePartsOpen] = useState(false);
	const [isBelongsToOpen, setIsBelongsToOpen] = useState(false);
	const [isProductQuestionsOpen, setIsProductQuestionsOpen] = useState(false);

	const [isTechnicalAttributesOpen, setIsTechnicalAttributesOpen] =
		useState(false);
	const [isReviewsOpen, setIsReviewsOpen] = useState(false);
	const [isImportantInfoOpen, setIsImportantInfoOpen] = useState(false);
	const urlHash = useHash();
	useEffectOnce(() => {
		if (urlHash === PRODUCT_TECHNICAL_ATTRIBUTES_ID) {
			setIsTechnicalAttributesOpen(true);
		}
		if (urlHash === PRODUCT_REVIEWS_ID) {
			setIsReviewsOpen(true);
		}
		if (urlHash === PRODUCT_IMPORTANT_INFO_ID) {
			setIsImportantInfoOpen(true);
		}
	});

	const {
		items: belongsToProducts,
		loadMore: loadMoreBelongsTo,
		isLoadingMore: belongsToIsLoadingMore,
		isLoading: belongsToIsLoading,
		hasNextPage: belongsToHasNextPage,
	} = useRelatedProducts(id, 'BelongsTo', hasBelongsTo && isBelongsToOpen);
	const {
		items: sparePartsProducts,
		loadMore: loadMoreSpareParts,
		isLoadingMore: sparePartsIsLoadingMore,
		isLoading: sparePartsIsLoading,
		hasNextPage: sparePartsHasNextPage,
	} = useRelatedProducts(id, 'SpareParts', hasSpareParts && isSparePartsOpen);
	const {
		items: accessoriesProducts,
		loadMore: loadMoreAccessories,
		isLoadingMore: accessoriesIsLoadingMore,
		isLoading: accessoriesIsLoading,
		hasNextPage: accessoriesHasNextPage,
	} = useRelatedProducts(id, 'Accessories', hasAccessories);

	const {
		items: productQuestions,
		newQuestionsAllowed,
		hasNextPage: productQuestionsHasNextPage,
		loadMore: loadMoreProductQuestions,
		isLoading: isLoadingInitialQuestions,
		isLoadingMore: isLoadingMoreQuestions,
	} = useProductQuestions(id);

	const [creditSimulationPopoverIsOpen, setCreditSimulationPopoverIsOpen] =
		useState(false);

	const {
		selectedStore,
		isLoading: selectedStoreIsLoading,
		setSelectedStore,
	} = useSelectedStore();
	const stockService = useInterpret(stockMachine, {
		context: {
			storeId: selectedStore?.id,
			currentProductId: product.id,
		},
		devTools: true,
	});
	const hasWebStock = useSelector(stockService, selectHasWebStock);
	const variantsStock = useSelector(stockService, selectVariantsStock);
	const currentProductStock = useSelector(
		stockService,
		selectCurrentProductStock,
	);
	const currentStoreStock = useSelector(stockService, selectCurrentStoreStock);
	const allStoresStock = useSelector(stockService, selectAllStoresStock);
	const nearbyStoresStock = useSelector(stockService, selectNearbyStoresStock);
	const isLoadingInitialStock = useSelector(
		stockService,
		selectIsLoadingInitialStock,
	);
	const isLoadingStock = useSelector(stockService, selectIsLoadingStock);
	const isLoadingNearbyStoresStock = useSelector(
		stockService,
		selectIsLoadingNearbyStoresStock,
	);
	const isLoadingVariantsStock = useSelector(
		stockService,
		selectIsLoadingVariantsStock,
	);
	const updateSelectedStore = (store: StockStore) => {
		if (store.id && store.name) {
			setSelectedStore({ id: store.id, name: store.name });
		}
	};

	useEffect(() => {
		if (!selectedStoreIsLoading && selectedStore) {
			stockService.send({
				type: 'FETCH_STOCK_FOR_STORE',
				storeId: selectedStore.id,
			});
		} else if (!selectedStoreIsLoading && !selectedStore) {
			stockService.send({
				type: 'FETCH_STOCK_FOR_ALL_STORES',
			});
		}
	}, [selectedStoreIsLoading, selectedStore, stockService]);

	const [selectedReviewGrade, setSelectedReviewGrade] = useState<
		number | undefined
	>(undefined);
	const [reviewsSortOption, setReviewsSortOption] = useState<string>();
	const {
		items: reviews,
		questionsSummary,
		isLoading: isLoadingInitialReviews,
		isLoadingMore: isLoadingMoreReviews,
		loadMore: loadMoreReviews,
		hasNextPage,
	} = useReviews(product.id, selectedReviewGrade, reviewsSortOption);
	const { reviewImages, selectedReviewImageId, setSelectedReviewImageId } =
		useReviewImages(product.id);
	const warningImages = getProductWarningImages(product);
	const verticalEnergyLabel = getVerticalEnergyLabel(product);
	const symbolImageUrls = getProductInformationSymbols(product);
	const documents = getDocuments(product);
	const technicalCategories = technicalAttributes?.categories;

	const brandLogoSrc = getBrandLogoImage(product);

	const hasChemicalStatements = Boolean(
		chemicalInformation?.hazardStatements?.length ||
			chemicalInformation?.supplementalHazardStatements?.length ||
			chemicalInformation?.precationaryStatements?.length,
	);

	const handleReviewGradeClick = (score: number) => {
		setSelectedReviewGrade(score);
	};
	const handleReviewGradeResetClick = () => {
		setSelectedReviewGrade(undefined);
	};

	useEffect(() => {
		pushToGTM({
			type: 'view_item',
			payload: { product, category: product.category1Name },
		});
		product.campaigns?.forEach((campaign) => {
			pushToGTM({
				type: 'view_item_list',
				payload: {
					itemListId: 'pdp_mix_match',
					itemListName: `Mix and Match: [${campaign.discountType}] - [${campaign.title}] ([${campaign.id}])`,
					products: [product],
					pageSize: 1,
				},
			});
		});
		if (is.arrayWithLength(similarProducts)) {
			pushToGTM({
				type: 'view_item_list',
				payload: {
					itemListId: 'pdp_similar_products',
					itemListName: `List: Similar products ${product.id}`,
					products: similarProducts,
					pageSize: similarProducts.length,
				},
			});
		}
		// The product object could in theory change while the ID stays the same,
		// in which case additional events would not be desired.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product.id]);

	const additionalTexts = (product.additionalTexts ?? []).filter(
		(text) => text && (text.title || text.textFormatted),
	);
	const descriptionAdditionalTexts = additionalTexts.filter((text) =>
		['Notification', 'Offer'].includes(text.type),
	);

	const wishlistButtonState = useSelector(
		wishlistService,
		selectWishlistButtonState,
	);
	const wishlistAdditionalSales = useSelector(
		wishlistService,
		selectWishlistAdditionalSales,
	);
	const wishlistAdditionalSalesIsOpen = useSelector(
		wishlistService,
		selectWishlistAdditionalSalesIsOpen,
	);

	const purchaseButtonState = useSelector(
		cartService,
		selectPurchaseButtonState,
	);
	const additionalSalesProducts = useSelector(
		cartService,
		selectAdditionalSalesProducts,
	);
	const additionalSalesIsOpen = useSelector(
		cartService,
		selectAdditionalSalesIsOpen,
	);
	const additionalSalesToastText = useSelector(
		cartService,
		selectAdditionalSalesToastText,
	);

	const { onlineCommerceEnabled } = useFeatureToggle();

	const {
		gtmItemListId: additionalSalesGTMItemListId,
		gtmItemListName: additionalSalesGTMItemListName,
		sendAddToCartEvent: sendAdditionalSalesAddEvent,
	} = useProductListGTMEvents(
		'pdp_add_to_cart_upsale',
		`List: Add to cart upsale ${id}`,
	);
	const {
		gtmItemListId: wishlistAdditionalSalesGTMItemListId,
		gtmItemListName: wishlistAdditionalSalesGTMItemListName,
		sendAddToWishlistEvent: sendWishlistAdditionalSalesAddEvent,
	} = useProductListGTMEvents(
		'pdp_add_to_wishlist_upsale',
		`List: Add to wishlist upsale ${id}`,
	);

	const {
		addPrintPlacement,
		addPrintPlacementButtonState,
		addPrintPlacementErrors,
		existingPrintPlacements,
		hasError: productPrintHasError,
		isInitialLoading: isInitialLoadingProductPrint,
		placements,
		print,
		printPlacements,
		printUploadButtonState,
		printUploadErrors,
		removePrint,
		removePrintPlacement,
		reusePrint,
		storedPrints,
		totalCost,
		uploadPrint,
	} = useProductPrint({ id, isActive: isCustomizable });

	return (
		<>
			<ProductDetailsBlock
				product={product}
				key={id}
				handleReviewGradeResetClick={handleReviewGradeResetClick}
				handleReviewGradeClick={handleReviewGradeClick}
				warningImages={warningImages}
				verticalEnergyLabel={verticalEnergyLabel}
				symbolImageUrls={symbolImageUrls}
				documents={documents}
				technicalCategories={technicalCategories}
				isLoadingMoreReviews={Boolean(isLoadingMoreReviews)}
				onLoadMoreReviewsClick={() => ignorePromiseRejection(loadMoreReviews())}
				hasMoreReviews={Boolean(hasNextPage)}
				reviews={reviews}
				reviewImages={reviewImages}
				selectedReviewImageId={selectedReviewImageId}
				setSelectedReviewImageId={setSelectedReviewImageId}
				questionsSummary={questionsSummary}
				isReviewsOpen={isReviewsOpen}
				isTechnicalAttributesOpen={isTechnicalAttributesOpen}
				isImportantInfoOpen={isImportantInfoOpen}
				onImportantInfoClick={() => {
					setIsImportantInfoOpen((current) => !current);
				}}
				isAboutBrandOpen={isAboutBrandOpen}
				onAboutBrandClick={() => setIsAboutBrandOpen((current) => !current)}
				isSparePartsOpen={isSparePartsOpen}
				onSparePartsClick={() => setIsSparePartsOpen((current) => !current)}
				isBelongsToOpen={isBelongsToOpen}
				onBelongsToClick={() => setIsBelongsToOpen((current) => !current)}
				hasChemicalStatements={hasChemicalStatements}
				brandLogoSrc={brandLogoSrc}
				creditSimulationPopoverIsOpen={creditSimulationPopoverIsOpen}
				onCreditSimulationPopoverClose={() =>
					setCreditSimulationPopoverIsOpen(false)
				}
				onTechnicalAttributesClick={() => {
					setIsTechnicalAttributesOpen((current) => !current);
				}}
				onReviewsClick={() => {
					setIsReviewsOpen((current) => !current);
				}}
				onReviewsLinkClick={() => {
					setIsReviewsOpen(true);
				}}
				fit3Summary={questionsSummary?.find(({ name }) => name === 'fit3')}
				isLoadingFit={isLoadingInitialReviews}
				isLoadingInitialReviews={isLoadingInitialReviews}
				allStoresStock={allStoresStock}
				currentProductStock={currentProductStock}
				onCampaignPopoverPurchaseButtonClick={(variant, campaign) => {
					cartService.send({
						type: 'ADD_ONE_FROM_BUY_BUTTON',
						variantId: variant.id,
						buttonId: `campaign-popover-${variant.id}`,
						requestAdditionalSales: false,
						showToast: true,
						GTMData: {
							type: 'add_to_cart',
							payload: {
								product: variant,
								quantity: 1,
								itemListId: 'pdp_mix_match',
								itemListName: `Mix and Match: [${campaign.discountType}] - [${campaign.title}] ([${campaign.id}])`,
							},
						},
					});
				}}
				descriptionAdditionalTexts={descriptionAdditionalTexts}
				additionalTexts={additionalTexts}
				currentStoreStock={currentStoreStock}
				isLoadingInitialStock={isLoadingInitialStock || !isStockActive}
				isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
				purchaseButtonState={purchaseButtonState}
				onMainPurchaseButtonClick={() => {
					cartService.send({
						type: 'ADD_ONE_FROM_BUY_BUTTON',
						variantId: product.id,
						requestAdditionalSales: true,
						showToast: true,
						GTMData: {
							type: 'add_to_cart',
							payload: { product, quantity: 1 },
						},
					});
				}}
				wishlistButtonState={wishlistButtonState}
				onMainWishlistButtonClick={() => {
					wishlistService.send({
						type: 'ADD_ONE_TO_WISHLIST',
						variantId: product.id,
						showToast: true,
						requestAdditionalSales: true,
						GTMData: {
							type: 'add_to_wishlist',
							payload: { product, quantity: 1 },
						},
					});
					sendGlobalEvent('engagement', { type: 'addArticleToWishlist' });
				}}
				onStockInformationTabChange={(tab) => {
					if (tab.includes('nearby')) {
						stockService.send({
							type: 'FETCH_STOCK_FOR_NEARBY_STORES',
						});
					} else {
						stockService.send({
							type: 'FETCH_STOCK_FOR_ALL_STORES',
						});
					}
				}}
				onStockInformationSearch={() => {
					stockService.send({
						type: 'FETCH_STOCK_FOR_ALL_STORES',
					});
				}}
				onStockInformationOpen={() => {
					if (selectedStore) {
						stockService.send({
							type: 'FETCH_STOCK_FOR_NEARBY_STORES',
						});
					} else {
						stockService.send({
							type: 'FETCH_STOCK_FOR_ALL_STORES',
						});
					}
					pushToGTM({ type: 'select_store_open' });
				}}
				onSizeGuideClick={() => {
					if (!sizeGuideUrl) return;
					globalPopoverService.send({
						type: 'OPEN',
						target: sizeGuideUrl,
						heading: t('product_details_sizeguide_heading'),
					});
				}}
				onVariantPickerOpen={() => {
					if (!is.arrayWithLength(variants)) return;
					stockService.send({
						type: 'FETCH_STOCK_FOR_VARIANTS',
						variantIds: variants.map((variant) => variant.id),
					});
				}}
				onCreditSimulationClick={() => {
					setCreditSimulationPopoverIsOpen(true);
					pushToGTM({ type: 'open_credit_simulation' });
				}}
				variantsStock={variantsStock}
				selectedStore={selectedStore}
				isLoadingVariantsStock={isLoadingVariantsStock}
				purchaseButtonDisabled={!salesAttributes?.isSellable || !hasWebStock}
				onUpdateSelectedStore={updateSelectedStore}
				nearbyStoresStock={nearbyStoresStock}
				isLoadingStock={isLoadingStock}
				reviewPolicyUrl={reviewPolicy}
				onWishlistAdditionalSalesClose={() => {
					wishlistService.send({ type: 'CLEAR_ADDITIONAL_SALES_PRODUCTS' });
				}}
				wishlistAdditionalSalesGTMItemListId={
					wishlistAdditionalSalesGTMItemListId
				}
				wishlistAdditionalSalesGTMItemListName={
					wishlistAdditionalSalesGTMItemListName
				}
				wishlistAdditionalSalesIsOpen={wishlistAdditionalSalesIsOpen}
				onWishlistAdditionalSalesButtonClick={(additionalSalesProduct) => {
					wishlistService.send({
						type: 'ADD_ONE_TO_WISHLIST',
						variantId: additionalSalesProduct.id,
						requestAdditionalSales: false,
						showToast: false,
					});
					sendWishlistAdditionalSalesAddEvent(additionalSalesProduct, 1);
					sendGlobalEvent('engagement', { type: 'addArticleToWishlist' });
				}}
				wishlistAdditionalSalesToastText={wishlistAdditionalSales?.toastText}
				wishlistAdditionalSalesProducts={wishlistAdditionalSales?.products}
				onAdditionalSalesClose={() => {
					cartService.send({ type: 'CLEAR_ADDITIONAL_SALES_PRODUCTS' });
				}}
				onAdditionalSalesButtonClick={(additionalSalesProduct) => {
					cartService.send({
						type: 'ADD_ONE_FROM_BUY_BUTTON',
						variantId: additionalSalesProduct.id,
						requestAdditionalSales: false,
						showToast: false,
					});
					sendAdditionalSalesAddEvent(additionalSalesProduct, 1);
					sendGlobalEvent('engagement', {
						type: 'addAccessoryFromAdditionalSales',
					});
				}}
				additionalSalesToastText={additionalSalesToastText}
				additionalSalesProducts={additionalSalesProducts}
				additionalSalesGTMItemListId={additionalSalesGTMItemListId}
				additionalSalesGTMItemListName={additionalSalesGTMItemListName}
				additionalSalesIsOpen={additionalSalesIsOpen}
				onMixAndMatchBuyAllFixedPriceAddToCartClick={
					onlineCommerceEnabled
						? (campaign: CampaignResponse) => {
								cartService.send({
									type: 'ADD_MULTIPLE_VARIANTS_MINI',
									variants: campaign.variants.map((variant) => ({
										variantId: variant.id,
										quantity: variant.quantity,
									})),
									buttonId: `addCampaignToCart-${campaign.title}-${campaign.validTo}`,
								});
							}
						: undefined
				}
				belongsToProducts={belongsToProducts}
				belongsToIsLoadingMore={Boolean(belongsToIsLoadingMore)}
				belongsToIsLoading={belongsToIsLoading}
				belongsToHasNextPage={belongsToHasNextPage}
				onBelongsToLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreBelongsTo())
				}
				sparePartsProducts={sparePartsProducts}
				sparePartsIsLoadingMore={Boolean(sparePartsIsLoadingMore)}
				sparePartsIsLoading={sparePartsIsLoading}
				sparePartsHasNextPage={sparePartsHasNextPage}
				onSparePartsLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreSpareParts())
				}
				accessoriesProducts={accessoriesProducts}
				accessoriesIsLoadingMore={Boolean(accessoriesIsLoadingMore)}
				accessoriesIsLoading={accessoriesIsLoading}
				accessoriesHasNextPage={accessoriesHasNextPage}
				onAccessoriesLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreAccessories())
				}
				productQuestions={productQuestions}
				productQuestionsHasNextPage={productQuestionsHasNextPage}
				productQuestionsIsLoading={isLoadingInitialQuestions}
				productQuestionsIsLoadingMore={Boolean(isLoadingMoreQuestions)}
				onProductQuestionsLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreProductQuestions())
				}
				isProductQuestionsOpen={isProductQuestionsOpen}
				onProductQuestionsClick={() =>
					setIsProductQuestionsOpen((current) => !current)
				}
				productQuestionsNewQuestionsAllowed={Boolean(newQuestionsAllowed)}
				reviewsSortOption={reviewsSortOption}
				onReviewsSortOptionChange={(option) => setReviewsSortOption(option)}
				isInitialLoadingProductPrint={isInitialLoadingProductPrint}
				productPrintHasError={productPrintHasError}
				addPrintPlacementButtonState={addPrintPlacementButtonState}
				addPrintPlacementErrors={addPrintPlacementErrors}
				existingPrintPlacements={existingPrintPlacements}
				onAddPrintPlacementClick={addPrintPlacement}
				onRemovePrintPlacementClick={removePrintPlacement}
				onReusePrintClick={reusePrint}
				placements={placements}
				printPlacements={printPlacements}
				storedPrints={storedPrints}
				print={print}
				fileUploadButtonState={printUploadButtonState}
				onUpLoadPrint={uploadPrint}
				printUploadErrors={printUploadErrors}
				productPrintTotalCost={totalCost}
				onRemovePrintClick={removePrint}
			/>
			{sitecoreContext.metaData?.structuredData && (
				<Script
					id={`application/ld+json${product.id}`}
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(sitecoreContext.metaData?.structuredData),
					}}
				/>
			)}
		</>
	);
}
ProductDetails.displayName = 'ProductDetails';

export default function ProductDetailsInstance(props: Props) {
	if (!props?.fields) {
		return null;
	}

	return (
		<ErrorBoundary isPageWidth>
			<ProductDetails {...props} key={props.fields.id} />
		</ErrorBoundary>
	);
}
ProductDetailsInstance.displayName = 'ProductDetailsInstance';
